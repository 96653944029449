<template>
    <div class="form-wrapper">
        <mt-cell class="form-item" title="寄回区域" is-link :value="ruleForm.address" @click.native="showAreaPopup=true" />
        <mt-field v-model="ruleForm.addressDesc" class="form-item" label="详细地址" type="textarea" :attr="{ maxlength: 200 }" rows="3" @keyup.native="addressDesc=addressDesc.replace(/\s+/g, '')" />
        <mt-field v-model="ruleForm.username" class="form-item" label="收件人" :attr="{ maxlength: 11 }" clearable @keyup.native="ruleForm.username=ruleForm.username.replace(/\s+/g, '')" />
        <mt-field v-model="ruleForm.mobile" class="form-item" label="联系方式" :attr="{ maxlength: 11 }" clearable @keyup.native="ruleForm.mobile=ruleForm.mobile.replace(/[^\d]/g, '')" />
        <section class="btn-wrapper">
            <a href="javascript:;" class="btn-primary btn-plain" @click="$emit('close')">取消</a>
            <a href="javascript:;" class="btn-primary btn-round" :class="{'disabled':isDisabled}" @click="onSubmit">保存</a>
        </section>
        <mt-popup
            v-model="showAreaPopup"
            class="popup-content"
            position="bottom"
        >
            <province-city-area @setFullAddress="getFullAddress" @close="showAreaPopup=false" />
        </mt-popup>
    </div>
</template>

<script>
import provinceCityArea from '@c/provinceCityArea'
import { addAftermarketDetail } from '@api/aftermarket'
import mixin from '@c/mixins'
export default {
    name: 'AddResDialog',
    components: {
        provinceCityArea
    },
    mixins: [mixin],
    props: {
        visible: {
            type: Boolean,
            default: false
        },
        aftermarketid: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            ruleForm: {
                type: 3,
                address: '',
                username: '',
                mobile: ''
            },
            addressDesc: '',
            showAreaPopup: false
        }
    },
    computed: {
        isDisabled() {
            return this.ruleForm.fareorder === '' || this.ruleForm.address === '' || this.ruleForm.desc === '' || this.ruleForm.username === '' || this.ruleForm.mobile === ''
        }
    },
    watch: {
        visible(val) {
            console.log('ruleForm', this.ruleForm)
            if (val) {
                this.$refs.ruleForm && this.$refs.ruleForm.resetFields()
                for (const key in this.ruleForm) {
                    this.ruleForm[key] = ''
                }
                this.ruleForm.type = 3
            }
        }
    },
    methods: {
        getFullAddress(addressObj) {
            console.log('full address', addressObj)
            this.ruleForm.address = addressObj.province.name + addressObj.city.name + addressObj.area.name
        },
        async onSubmit() {
            if (this.isDisabled) return
            console.log('form', this.ruleForm)
            this.ruleForm.address += this.addressDesc
            const res = await addAftermarketDetail({ aftermarketid: this.aftermarketid, ...this.ruleForm })
            console.log('addAftermarketDetail res', res)
            const resDetail = res.detail
            const resStatus = resDetail.status
            this.$toast(resStatus === 1 ? '操作成功' : resDetail.msg)
            if (resStatus === 1) {
                this.$emit('getData')
                this.$emit('update:visible', false)
                this.$emit('close')
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.btn-wrapper {
    padding: 0 20px;
    margin: 20px 0;
    .btn-primary {
        display: inline-block;
        width: calc(50% - 5px);
        &.disabled{
            background: #d4d4d4;border-color: #d4d4d4;
        }
    }
}
</style>
