<template>
    <div class="address-page">
        <popup-header :title="`${value?'编辑':'新增'}收货地址`" @close="$emit('close')" />
        <div class="form-wrapper">
            <!-- <el-dialog class="dialog-my" :before-close="closeDialog" :title="type==='add'?'新增地址':'编辑地址'" :close-on-click-modal="false" :visible.sync="visible" width="460px">
                <el-form ref="ruleForm" :model="ruleForm" class="simple-form">
                    <el-form-item prop="name" label="收货人姓名" :rules="[{ required: true, message: '请输入收货人姓名', trigger: 'blur' }]">
                        <el-input v-model.trim="ruleForm.name" :maxlength="16" clearable @keyup.native="ruleForm.name=ruleForm.name.replace(/\s+/g, '')" @keyup.enter.native="onSubmit" />
                    </el-form-item>
                    <el-form-item prop="mobile" label="手机号" :rules="[{ required: true, message: '请输入手机号', trigger: 'blur' }, { pattern: reg.tel, message: reg.telPrompt, trigger: 'blur' }]">
                        <el-input v-model.trim="ruleForm.mobile" :maxlength="11" clearable @keyup.native="ruleForm.mobile=ruleForm.mobile.replace(/[^\d]/g, '')" @keyup.enter.native="onSubmit" />
                    </el-form-item>
                    <el-form-item prop="areacode" label="所在区域" :rules="[{ required: true, message: '请选择所在区域', trigger: 'change' }]"> -->
                        <!-- <el-cascader v-if="showCascader" v-model="areaArr" :props="{ lazy: true, lazyLoad: lazyLoad }" clearable @change="onChange" />
                        <province-city-area ref="provinceCityArea" :provincecode-default="ruleForm.provincecode" :citycode-default="ruleForm.citycode" :areacode-default="ruleForm.areacode" @setFullAddress="getFullAddress" />
                    </el-form-item>
                    <el-form-item prop="address" label="详细地址" :rules="[{ required: true, message: '请输入详细地址', trigger: 'blur' }]">
                        <el-input v-model="ruleForm.address" type="textarea" size="medium" placeholder="请输入详细地址" maxlength="200" show-word-limit clearable @keyup.enter.native="onSubmit" />
                    </el-form-item>
                </el-form>
                <div slot="footer" class="dialog-footer">
                    <a href="javascript:;" class="btn-primary btn-round" @click="onSubmit">保存</a>
                </div>
            </el-dialog> -->
            <section class="form-item">
                <mt-field label="收货人姓名" placeholder="请添加收货人姓名" :attr="{ maxlength: 16 }" v-model="ruleForm.name" @keyup.native="ruleForm.name=ruleForm.name.replace(/\s+/g, '')" clearable />
                <mt-field label="手机" placeholder="请填写手机号码" :attr="{ maxlength: 11 }" v-model="ruleForm.mobile" @keyup.native="ruleForm.mobile=ruleForm.mobile.replace(/[^\d]/g, '')" clearable />
            </section>
            <section class="form-item">
                <mt-cell class="form-item" title="所在区域" placeholder="省市区" is-link :value="provinceCityArea" @click.native="showAreaPopup=true"></mt-cell>
            </section>
            <mt-field class="form-item" label="详细地址" type="textarea" :attr="{ maxlength: 200 }" rows="3" v-model="ruleForm.address" />
        </div>
        <section class="btn-wrapper">
            <a href="javascript:;" class="btn-primary btn-round" @click="onSubmit">保存</a>
        </section>
        <mt-popup
            class="popup-content"
            v-model="showAreaPopup"
            position="bottom">
                <province-city-area ref="provinceCityArea" @setFullAddress="getFullAddress" @close="showAreaPopup=false" />
        </mt-popup>
    </div>
</template>

<script>
import { addaddress, editaddress } from '@api/my'
import provinceCityArea from '@c/provinceCityArea'
import popupHeader from '@c/popupHeader'
import mixin from '@c/mixins'
export default {
    name: 'AddressDialog',
    mixins: [mixin],
    props: ['value'],
    components: {
        provinceCityArea,
        popupHeader
    },
    data() {
        return {
            ruleForm: {
                name: '',
                mobile: '',
                address: '',
                provincecode: '',
                citycode: '',
                areacode: ''
            },
            areaArr: [],
            showCascader: false,
            resolve: null,
            showAreaPopup: false,
            provinceCityArea: ''
            // props: {
            //     lazy: true,
            //     lazyLoad (node, resolve) {
            //         const { level } = node;
            //         setTimeout(() => {
            //         const nodes = Array.from({ length: level + 1 })
            //             .map(item => ({
            //             value: ++id,
            //             label: `选项${id}`,
            //             leaf: level >= 2
            //             }));
            //         // 通过调用resolve将子节点数据返回，通知组件数据加载完成
            //         resolve(nodes);
            //         }, 1000);
            //     }
            // }
        }
    },
    watch: {
        value(val) {
            console.log('val', val)
            // if (val) {
                // this.$refs.ruleForm && this.$refs.ruleForm.resetFields()
                this.ruleForm.provincecode = ''
                if (val) {
                    for (const key in this.ruleForm) {
                        this.ruleForm[key] = this.value[key]
                    }
                    const { province, city, area } = val
                    this.provinceCityArea = province + city + area
                } else {
                    this.$refs.provinceCityArea && this.$refs.provinceCityArea.setProvince()
                    this.resetForm()
                    // this.areaArr = [this.ruleForm.provincecode, this.ruleForm.citycode, this.ruleForm.areacode]
                    // const currentProvinceObj = this.provinceList.find(item => item.provincecode === this.provinceList) || this.provinceList[0]
                    // const provinceNode = {
                    //     value: currentProvinceObj.code,
                    //     label: currentProvinceObj.name,
                    //     leaf: false
                    // }
                    // this.lazyLoad(provinceNode, this.resolve)
                }
                this.$set(this.ruleForm, 'user_address_id', this.value.user_address_id)
                // this.showCascader = true // 切换地址选择级联重新渲染
            // }
            // else {
            //     this.showCascader = false
            // }
            // console.log('value, ruleForm', this.value, this.ruleForm)
            // console.log('areaArr', this.areaArr)
        }
    },
    methods: {
        // async lazyLoad(node, resolve) {
        //     console.log('lazyLoad node', node)
        //     this.resolve = resolve
        //     const { level, value } = node
        //     const getMapData = (list) => {
        //         return list.map(item => ({
        //             value: item.code,
        //             label: item.name,
        //             leaf: level >= 2
        //         }))
        //     }
        //     // 省节点
        //     if (level === 0) {
        //         const nodes = getMapData(this.provinceList)
        //         // 通过调用resolve将子节点数据返回，通知组件数据加载完成
        //         console.log('level === 0 nodes', nodes)
        //         resolve(nodes)
        //         const currentProvinceObj = this.provinceList.find(item => item.provincecode === this.ruleForm.provincecode) || this.provinceList[0]
        //         const provinceNode = {
        //             value: currentProvinceObj.code,
        //             label: currentProvinceObj.name,
        //             level: 1,
        //             leaf: false
        //         }
        //         // this.lazyLoad(provinceNode, this.resolve)
        //     } else if (level === 1) {
        //         const res = await getCity({ provincecode: value })
        //         const nodes = getMapData(res.detail)
        //         // 通过调用resolve将子节点数据返回，通知组件数据加载完成
        //         resolve(nodes)
        //     } else if (level === 2) {
        //         const res = await getArea({ citycode: value })
        //         const nodes = getMapData(res.detail)
        //         // 通过调用resolve将子节点数据返回，通知组件数据加载完成
        //         resolve(nodes)
        //     }
        // },
        // closeDialog() {
        //     this.$emit('update:visible', false)
        //     this.resetForm()
        // },
        resetForm() {
            for (const key in this.ruleForm) {
                this.ruleForm[key] = ''
            }
            // this.areaArr = ['', '', '']
            delete this.ruleForm.user_address_id
        },
        getFullAddress(addressObj) {
            console.log('full address', addressObj)
            this.ruleForm.provincecode = addressObj.province.code
            this.ruleForm.citycode = addressObj.city.code
            this.ruleForm.areacode = addressObj.area.code
            this.provinceCityArea = addressObj.province.name + addressObj.city.name + addressObj.area.name
        },
        // onChange(val) {
        //     console.log(val)
        //     this.ruleForm.provincecode = val[0]
        //     this.ruleForm.citycode = val[1]
        //     this.ruleForm.areacode = val[2]
        // },
        async onSubmit() {
            console.log('this.ruleForm', this.ruleForm)
            // this.$refs['ruleForm'].validate(async valid => {
            //     if (valid) {
                    const res = !this.value ? await addaddress(this.ruleForm) : await editaddress(this.ruleForm)
                    // this.$notify({ title: '提示', type: res.detail.status === 1 ? 'success' : 'error', message: res.msg, position: 'bottom-right' })
                    this.$toast(res.msg)
                    this.$emit('getData')
                    this.$emit('close', true)
                    // this.$emit('update:visible', false)
                    // this.showCascader = falses
            //     }
            // })
        }
    }
}
</script>

<style lang="scss" scoped>
.address-page {
    position: absolute;
    z-index: 1;
    top: 40px;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $lightest;
}
.form-wrapper {
    background-color: $white;
}
</style>
