import Vue from 'vue'
// 按需引入部分组件
import {
    Header,
    Cell,
    Checklist,
    Button,
    Field,
    Toast,
    Tabbar,
    TabItem,
    Popup,
    Navbar,
    TabContainer,
    TabContainerItem,
    Switch,
    Picker,
    // Radio
} from 'mint-ui'
Vue.component(Cell.name, Cell)
Vue.component(Checklist.name, Checklist)
Vue.component(Button.name, Button)
Vue.component(Field.name, Field)
Vue.component(Header.name, Header)
Vue.component(Tabbar.name, Tabbar)
Vue.component(TabItem.name, TabItem)
Vue.component(Popup.name, Popup)
Vue.component(Navbar.name, Navbar)
Vue.component(TabContainer.name, TabContainer)
Vue.component(TabContainerItem.name, TabContainerItem)
Vue.component(Switch.name, Switch)
Vue.component(Picker.name, Picker)
// Vue.component(Radio.name, Radio)
// Vue.prototype.$toast = Toast