<template>
    <mt-tabbar v-if="selected" v-model="selected" class="footer-tab" fixed>
        <mt-tab-item id="tab1" @click.native="$router.push('/')">
            <i class="iconfont icon-home" />
            <span class="tab-txt">首页</span>
        </mt-tab-item>
        <mt-tab-item id="tab2" @click.native="$router.push('/news')">
            <i class="iconfont icon-zixun" />
            <span class="tab-txt">资讯</span>
        </mt-tab-item>
        <mt-tab-item id="tab3" @click.native="$router.push('/product')">
            <i class="iconfont icon-xiong" />
            <span class="tab-txt">产品</span>
        </mt-tab-item>
        <mt-tab-item id="tab4" @click.native="$router.push('/my')">
            <i class="iconfont icon-mine" />
            <span class="tab-txt">我的</span>
        </mt-tab-item>
    </mt-tabbar>
</template>
<script>
export default {
    name: 'PageFooter',
    props: {
        path: {
            type: String,
            default: '/'
        }
    },
    data() {
        return {
            selected: '',
        }
    },
    watch: {
        '$route'(val) {
            if (val.path === '/news') {
                this.selected = 'tab2'
            } else if (val.path === '/product') {
                this.selected = 'tab3'
            } else if (val.path === '/my') {
                this.selected = 'tab4'
            } else if (val.path === '/') {
                this.selected = 'tab1'
            } else {
                this.selected = ''
            }
        },
        path(val) {
            console.log('path', val)
        },
        selected(val) {
            const path = {
                'tab1': '/',
                'tab2': '/news',
                'tab3': '/product',
                'tab4': '/my'
            }
            console.log('val', val)
            // if (this.$route.path !== path[val]) {
                // this.$router.push(path[val])
            // }
        }
    },
    methods: {

    }
}
</script>
<style lang="scss" scoped>
.tab-txt {
    margin-top: 2px;
    display: block;
}
.iconfont {
    font-size: $fs24;
}
.footer-tab {
    border-top: 1px solid $lightest;
    background-color: $white;
    &.mint-tabbar > .mint-tab-item.is-selected {
        background-color: transparent;
    }
    .is-selected {
        .iconfont,
        .tab-txt {
            color: $red;
        }
    }
}
</style>
