<template>
    <div class="my-follow">
        <!-- <div class="my-head">
            <h3 class="my-title">
                <span>我的关注</span>
                <small class="sub-title">关注的商品可购买时会在这里提醒</small>
            </h3>
        </div> -->
        <!-- <page-header :userInfo="userInfo" :hasMore="false" title="我的关注" /> -->
        <section class="info-wrapper">
            <info-list :info-list="infoList" />
        </section>
        <section v-if="infoList.length===0" class="follow-none">
            <i class="iconfont icon-heart-plus"></i>
            <p class="follow-tips">暂未关注任何最新产品哦~</p>
            <p class="follow-btn">
                <router-link to="/" class="btn-primary btn-plain">去首页</router-link>
            </p>
        </section>
    </div>
</template>
<script>
import pageHeader from '@c/pageHeader'
import infoList from '@c/infoList.vue'
import { userCollection } from '@api/my'
// import '../mock'
export default {
    name: 'MyFollow',
    props: {
        userInfo: {
            type: Object
        }
    },
    components: {
        pageHeader,
        infoList
    },
    data() {
        return {
            // type: 1.可预订;2.未出货;3.有现货;
            infoList: [
                // { informationid: '1', img: require('@a/img/product2.png'), title: '次元造物最新素体展示次元造物最新素次元造物最新素', publishtype: 2, publishyear: '2020', publishtime: 'Q2', rmb: 1898.00, seriestitle: '次元造物', ontime: '2019.10.16', type: 1 },
                // { informationid: '2', img: require('@a/img/product2.png'), title: '次元造物最新素体展示次元造物最新素次元造物最新素', publishtype: 2, publishyear: '2020', publishtime: 'Q2', rmb: 1898.00, seriestitle: '次元造物', ontime: '2019.10.16', type: 2 },
                // { informationid: '3', img: require('@a/img/product2.png'), title: '次元造物最新素体展示次元造物最新素次元造物最新素', publishtype: 2, publishyear: '2020', publishtime: 'Q2', rmb: 1898.00, seriestitle: '次元造物', ontime: '2019.10.16', type: 3 },
                // { informationid: '4', img: require('@a/img/product2.png'), title: '次元造物最新素体展示次元造物最新素次元造物最新素', publishtype: 2, publishyear: '2020', publishtime: 'Q2', rmb: 1898.00, seriestitle: '次元造物', ontime: '2019.10.16' },
                // { informationid: '5', img: require('@a/img/product2.png'), title: '次元造物最新素体展示次元造物最新素次元造物最新素', publishtype: 2, publishyear: '2020', publishtime: 'Q2', rmb: 1898.00, seriestitle: '次元造物', ontime: '2019.10.16' },
                // { informationid: '6', img: require('@a/img/product2.png'), title: '次元造物最新素体展示次元造物最新素次元造物最新素', publishtype: 2, publishyear: '2020', publishtime: 'Q2', rmb: 1898.00, seriestitle: '次元造物', ontime: '2019.10.16' }
            ]
        }
    },
    created() {
        // 获取关注列表
        this.userCollection()
    },
    methods: {
        async userCollection() {
            const res = await userCollection()
            console.log('userCollection res', res)
            const list = res.list
            if (res.code === 1001) {
                this.infoList = res.list.list
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.my-follow {
    padding: 15px 0;
}
.info-wrapper {
    padding: 0 15px 15px;
}
.my-title {
    margin-bottom: 20px;
    font-weight: normal;
    font-size: 18px;
    .sub-title {
        padding: 0 5px;
        font-size: 12px;
        color: $light;
    }
}
.follow-none {
    margin: 100px 0;
    text-align: center;
    .iconfont {
        display: block;
        font-size: 80px;
        color: $light;
    }
    .btn-plain {
        width: 120px;
        border-color: $blue;
        // border-radius: 40;
        color: $blue;
    }
}
.follow-tips {
    margin-bottom: 30px;
    font-size: $fs14;
    color: $light;
}
</style>
