<template>
    <section class="order-price">
        <dl class="price-item">
            <dt class="price-item-type">订单总额</dt>
            <dd class="price-item-num">￥{{ priceObj.allmoney }}</dd>
        </dl>
        <dl class="price-item">
            <dt class="price-item-type">运费</dt>
            <dd class="price-item-num">￥{{ priceObj.yunfei }}</dd>
        </dl>
        <dl class="price-item">
            <dt class="price-item-type">优惠抵扣</dt>
            <dd class="price-item-num">￥{{ priceObj.youhui }}</dd>
        </dl>
        <dl class="price-item">
            <dt class="price-item-type">K点抵扣</dt>
            <dd class="price-item-num">￥{{ priceObj.integral }}</dd>
        </dl>
        <dl class="price-item">
            <dt class="price-item-type">已付金额</dt>
            <dd class="price-item-num">￥{{ priceObj.yifumoney }}</dd>
        </dl>
        <dl class="price-item price-order">
            <!-- <dt class="price-item-type">订单金额</dt> -->
            <dt class="price-item-type">待付金额</dt>
            <dd class="price-item-num">￥{{ priceObj.daifumoney }}</dd>
        </dl>
    </section>
</template>
<script>
export default {
    name: 'OrderPrice',
    props: {
        priceObj: {
            type: Object,
            default: null,
            required: true
        }
    }
}
</script>
<style lang="scss" scoped>
.order-price {
    padding: 10px;
}
.price-item {
    display: flex;
    justify-content: space-between;
    margin: 10px 0;
    font-size: 14px;
    // text-align: right;
    color: $black;
    &.price-order {
        padding-top: 10px;
        border-top: 1px solid $lightest;
        .price-item-num {
            font-weight: bold;
            font-size: 20px;
            color: $red;
        }
    }
}
</style>
