<template>
<section class="banner-wrapper">
    <swiper ref="swiperTop" class="banner" :options="swiperOptions" @slideChange="getIndex">
        <swiper-slide v-for="item in list" :key="item.id">
            <a v-if="item.link" class="swiper-link" :href="item.link">
                <div class="img-box" :style="{ backgroundImage: `url(${item.img})` }" />
                <span class="img-mask">
                    <!-- <span class="img-title">全职高手-叶修<span class="subtitle">【次元造物】</span></span> -->
                </span>
            </a>
            <router-link v-else class="swiper-link" :to="bannerUrl(item)">
                <div class="img-box" :style="{ backgroundImage: `url(${item.img})` }" />
                <span class="img-mask">
                    <!-- <span class="img-title">全职高手-叶修<span class="subtitle">【次元造物】</span></span> -->
                </span>
            </router-link>
        </swiper-slide>
        <div slot="pagination" class="swiper-pagination" />
    </swiper>
    <!-- <swiper ref="swiperThumbs" class="swiper-thurm" :options="swiperThrumOptions">
        <swiper-slide v-for="item in list" :key="item.id" class="swiper-thurm-item">
            <img class="swiper-thurm-img" :src="item.img">
            <span class="swiper-thurm-txt">全职高手-叶修【次元造物】</span>
        </swiper-slide>
    </swiper> -->
    <div class="swiper-thurm">
        <ul class="swiper-thurm-list">
            <li v-for="(item, index) in list" :key="item.id" class="swiper-thurm-item" :class="{ active: activeIndex === index }" @click="swiper.slideTo(index)">
                <img class="swiper-thurm-img" :src="item.img">
                <span class="swiper-thurm-txt">{{ item.title }}</span>
            </li>
        </ul>
    </div>
</section>
</template>
<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
export default {
    name: 'HomeBanner',
    components: {
        Swiper,
        SwiperSlide
    },
    props: {
        list: {
            type: Array,
            required: true,
            default: null
        }
    },
    data() {
        return {
            swiperOptions: {
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true
                },
                // autoplay: true, // 等同于以下设置
                // loop: true,
                // loopAdditionalSlides: 2,
                autoplay: {
                    delay: 10000,
                    stopOnLastSlide: false,
                    disableOnInteraction: false
                }
                // Some Swiper option/callback...
            },
            // swiperThrumOptions: {
            //     spaceBetween: 10,
            //     centeredSlides: true,
            //     slidesPerView: 'auto',
            //     touchRatio: 0.2,
            //     slideToClickedSlide: true,
            //     watchSlidesVisibility: true,
            //     // autoplay: true, // 等同于以下设置
            //     // loop: true,
            //     // loopAdditionalSlides: 2,
            // },
            activeIndex: 0,
        }
    },
    computed: {
        swiper() {
            return this.$refs.swiperTop.$swiper
        },
        // swiperThumb() {
        //     return this.$refs.swiperThumbs.$swiper
        // }
    },
    // updated () {
    //     if (this.isInit === 1) {
    //         this.$nextTick(() => {
    //             this.swiper.controller.control = this.swiperThumb
    //             this.swiperThumb.controller.control = this.swiper
    //         })
    //         this.isInit = 0
    //     }
    // },
    // mounted() {
    //     console.log(this.swiper)
    //     this.$nextTick(() => {
    //         // console.log('this.$refs.swiperTop', this.$refs.swiperTop)
    //         // const swiperTop = this.$refs.swiperTop.swiper
    //         // const swiperThumbs = this.$refs.swiperThumbs.swiper
    //         this.swiper.controller.control = this.swiperThumb
    //         this.swiperThumb.controller.control = this.swiper
    //     })
    // },
    methods: {
        bannerUrl(item) {
            return item.informationid !== '0' ? `/news/detail?id=${item.informationid}` : `/product/detail?commodityid=${item.commodityid}`
        },
        getIndex(e) {
            console.log('active index', this.swiper.activeIndex)
            this.activeIndex = this.swiper.activeIndex
        }
    }
}
</script>
<style lang="scss" scoped>
.banner {
    height: 240px;
    background-color: $light;
}
.swiper-link {
    position: relative;
    display: block;
    height: 100%;
    text-align: center;
    background-color: #eceff8;
    // > img {
    //     display: block;
    //     width: auto;
    //     height: 100%;
    //     margin: 0 auto;
    // }
}
.img-box {
    display: block;
    height: 100%;
    // width: 1200px;
    margin: 0 auto;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}
.img-mask {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    display: block;
    height: 60px;
    text-align: left;
    font-size: $fs16;
    background-image: linear-gradient(transparent, $black);
}
.img-title {
    padding: 5px 80px 20px 20px;
    display: block;
    color: $white;
}
.subtitle {
    margin-top: 5px;
    display: block;
    font-size: $fs14;
}
.swiper-thurm {
    // padding: 10px;
    margin-bottom: 10px;
    // .swiper-wrapper {
    //     align-items: flex-start;
    //     background-color: $white;
    // }
}
.swiper-thurm-list {
    display: flex;
    padding: 0 10px;
    background-color: $white;
}
.swiper-thurm-item {
    flex: 0 0 42px;
    height: 52px;
    padding: 10px 0;
    // border: 1px solid $light;
    &.active {
        flex: 0 0 140px;
        border-bottom: 2px solid $red;
        .swiper-thurm-txt {
            display: inline-block;
        }
    }
}
.swiper-thurm-img {
    @include inlineBlock();
    margin-right: 5px;
    width: 30px;
    height: 100%;
}
.swiper-thurm-txt {
    @include inlineBlock();
    width: 80px;
    display: none;
    color: $red;
}
</style>
