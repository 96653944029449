<template>
    <div class="my-accout">
        <!-- <page-header :userInfo="userInfo" title="我的" /> -->
        <div v-if="userInfo" class="my-user" @click="chooseType">
            <img class="mu-img" :src="imageUrl" alt="">
            <span class="mu-txt">点击更换头像</span>
            <input id="upload_file" type="file" class="file-input" accept="image/png,image/jpeg,image/jpg" :multiple="false" @change="inputChange" style="display: none" />
        </div>
        <div class="form-wrapper">
            <mt-field class="form-item mt-field-phone" label="昵称" :attr="{ maxlength: 16 }" v-model="ruleForm.username" clearable />
            <mt-cell class="form-item" title="性别" is-link :value="sexMap[ruleForm.sex]" @click.native="showSexPopup=true"></mt-cell>
            <mt-field class="form-item mt-field-date" label="生日" type="date" v-model="ruleForm.birthday" />
            <mt-field class="form-item mt-field-psd" label="密码" type="password" disabled :attr="{ maxlength: 16 }" v-model="ruleForm.password">
                <router-link class="btn-tag" to="/password">修改</router-link>
            </mt-field>
            <mt-cell class="form-item" title="收货地址" is-link to="/my/address" :value="ruleForm.address"></mt-cell>
        </div>
        <section class="btn-wrapper">
            <a href="javascript:;" class="btn-primary btn-block" @click="onSubmit">保存</a>
        </section>
        <mt-popup
            class="popup-content"
            v-model="showSexPopup"
            position="bottom">
            <a v-for="(value, key) in sexMap" :key="key" class="popup-item text-ellipsis" :class="{active: ruleForm.sex===key}" @click.prevent="switchSex(key, value)" :title="value">{{ value }}</a>
        </mt-popup>
    </div>
</template>
<script>
import pageHeader from '@c/pageHeader'
import updateFile from "@c/uploadFile";
// import { getUserAccount } from '@api'
import { editUserAccount, getUsernameTrue } from '@api/my'
export default {
    name: 'MyAccout',
    props: {
        userInfo: {
            type: Object
        }
    },
    components: {
        pageHeader,
        updateFile,
    },
    data() {
        return {
            sexMap: {
                '0': '保密',
                '1': '男',
                '2': '女',
            },
            showSexPopup: false,
            ruleForm: {
                // files : '', // 头像
                username: '', // 用户昵称
                sex: '0', // 性别
                birthday: '', // 生日
                // phone: '',
                address: '',
                password: '******',
                imgFile: null
            },
            // headImg: require('@a/img/my-head.jpg'),
            imageUrl: require('@a/img/my-head.jpg'),
            pickerOptions: {
                disabledDate(time) {
                    return time.getTime() > Date.now()
                }
            }
        }
    },
    watch: {
        userInfo: {
            handler(val) {
                console.log('val', val)
                this.updateForm(val)
            },
            deep: true
        }
    },
    created() {
        console.log('this.userInfo', this.userInfo)
        if(this.userInfo) this.updateForm(this.userInfo)
    },
    methods: {
        chooseType() {
            document.getElementById("upload_file").click();
        },
        upload(file, index) {
            return new Promise(async (resolve, reject) => {
                let form = new FormData();
                form.append("files", file);
                form.append("frames", 1);//根据上传入参添加参数
                console.log('form', form)
                // let result = await Http({
                //     url: '/aftermarket/imgUpload',
                //     method: 'post',
                //     data: form
                // });
                // if (result.code !== 1001) {//失败处理
                //     //   this.$ui.toast(`第${index + 1}张(${file.name})上传出错(已忽略)`);
                //     resolve(result);
                //     return;
                // }
                // this.list.push(result.detail.imgurl);
                // if (this.list.length === this.max) {
                //     this.maxStatus = false;
                // }
                // resolve(result);
            });
        },
        async inputChange(e) {
            console.log('e', e)
            let files = e.target.files;
            if(files[0]) {
                const windowURL = window.URL || window.webkitURL;
                this.imageUrl = windowURL.createObjectURL(files[0])
                this.ruleForm.imgFile = files[0]
            }
            // let len = this.list.length + files.length;
            // console.log('[].slice.call(files ,0)', [].slice.call(files ,0))
            // let uploadAll = [].slice.call(files ,0).map(this.upload);
            // // //使用object.values(files)，测试安卓存在兼容性问题，替换为[].slice.call(files ,0)
            // // this.$toast('上传中...')
            // let result = await Promise.all(uploadAll);
            // document.getElementById("upload_file").value = "";
            //   this.$ui.loading.close();
        },
        switchSex(key, value) {
            this.showSexPopup = false
            this.ruleForm.sex = key
        },
        updateForm(userInfo) {
            // 更新当前表单信息
            this.ruleForm = Object.assign({}, this.ruleForm, userInfo)
            this.ruleForm.birthday = userInfo.birthday === '0000-00-00' ? '' : userInfo.birthday
            this.imageUrl = this.ruleForm.headimgurl
        },
        // 上传头像
        // onUploadChange(file) {
        //     console.log(file.raw.type, file)
        //     const isJPG = ['image/jpeg', 'image/png', 'image/gif'].includes(file.raw.type)
        //     const isLt2M = file.size / 1024 / 1024 < 3
        //     if (!isJPG) {
        //         this.$message.error('上传头像图片只能是 JPG/PNG 格式!')
        //         return
        //     }
        //     if (!isLt2M) {
        //         this.$message.error('上传头像图片大小不能超过 3MB!')
        //         return
        //     }
        //     this.ruleForm.imgFile = file.raw
        //     this.imageUrl = URL.createObjectURL(file.raw)
        // },
        // 用户名查重
        async getUsernameTrue() {
            const res = await getUsernameTrue({ username: this.ruleForm.username })
            if (res.detail && res.detail.status) {
                const msg = ['用户名没有重复', '用户名重复了'][ res.detail.status - 1 ]
                // this.$notify({ title: '提示', type: res.detail.status === 1 ? 'success' : 'warning', message: msg, position: 'bottom-right' })
                this.$toast(msg)
            }
        },
        async onSubmit() {
            console.log(this.ruleForm.imgFile)
            const data = {
                frames: 1,
                files: this.ruleForm.imgFile,
                ...this.ruleForm
            }
            // this.$refs['ruleForm'].validate(async valid => {
                // if (valid) {
                    const res = await editUserAccount(data)
                    if (res && res.detail) {
                        // this.$notify({ title: '提示', type: res.detail.status === 1 ? 'success' : 'error', message: res.msg, position: 'bottom-right' })
                        this.$toast(res.msg)
                        // 此处需要刷新以更新头部用户信息(后续可使用vuex优化)
                        location.reload()
                    }
                // }
            // })
        }
    }
}
</script>
<style lang="scss" scoped>
.my-title {
    margin-bottom: 20px;
    font-weight: normal;
    font-size: 18px;
}
.my-user {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 150px;
    padding: 30px 20px;
    background: url(../../../assets/img/my-bg.jpg) center top no-repeat;
    .iconfont {
        font-size: $fs36;
        color: $white;
    }
}
.mu-img {
    width: 60px;
    flex: 0 0 60px;
    border-radius: 50%;
    object-fit: cover;
}
.mu-txt {
    padding: 10px 0;
    display: flex;
    flex-direction: column;
    font-size: $fs16;
    color: $white;
}
.form-wrapper {
    padding: 0 10px;
    background-color: $white;
    /deep/ {
        .mint-cell-title {
            width: 80px;
            color: $dark;
        }
        .mint-cell {
            border-bottom: 1px solid $lightest;
        }
    }
}
.mt-field-phone {
    /deep/ {
        .mint-cell-value,
        .mint-field-core {
            padding: 0 10px;
            border-radius: 12px;
        }
        .mint-cell-value {
            border: 1px solid $lighter;
        }
    }
}
.mt-field-psd {
    /deep/ {
        .mint-field-other {
            top: -3px;
        }
        .mint-cell-value {
            flex: 0 0 150px;
        }
    }
}
.btn-wrapper {
    position: absolute;
    bottom: 60px;
    left: 0;
    width: calc(100% - 40px);
    margin: 20px;
    .btn-primary {
        width: 100%;
        height: 42px;
        line-height: 40px;
        border-radius: 0;
        font-size: $fs16;
    }
}
</style>
