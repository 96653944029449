<template>
    <section class="order-price">
        <dl class="price-item">
            <dt class="price-item-type">订单总额</dt>
            <dd class="price-item-num">{{ `￥${priceObj.orderPrice}` }}</dd>
        </dl>
        <dl class="price-item">
            <dt class="price-item-type">运费</dt>
            <dd class="price-item-num">{{ `￥${priceObj.faremoney}` }}</dd>
        </dl>
        <!-- <dl class="price-item">
            <dt class="price-item-type">K点抵扣</dt>
            <dd class="price-item-num">{{ priceObj.c }}</dd>
        </dl> -->
        <dl class="price-item price-order">
            <dt class="price-item-type">订单金额</dt>
            <dd class="price-item-num">{{ `￥${priceObj.total}` }}</dd>
        </dl>
    </section>
</template>
<script>
export default {
    name: 'OrderPrice',
    props: {
        priceObj: {
            type: Object,
            default: null,
            required: true
        }
    }
}
</script>
<style lang="scss" scoped>
.order-price {
    padding: 10px 0;
    background-color: $white;
}
.price-item-type,
.price-item-num {
    // @include inlineBlock();
    flex: 1;
    // width: 120px;
}
.price-item-num {
    text-align: right;
}
.price-item {
    display: flex;
    padding: 0 20px;
    justify-content: space-around;
    margin: 5px 0;
    font-size: 14px;
    // color: $light;
    &.price-order {
        .price-item-num {
            font-weight: bold;
            font-size: 20px;
            color: $red;
        }
    }
}
</style>
