<template>
    <!-- <header class="header" :class="{ 'is-login': userInfo }"> -->
    <mt-header fixed :title="title">
        <span slot="left">
            <mt-button icon="back" @click.native="$emit('close')"></mt-button>
        </span>
        <!-- <mt-button v-if="hasMore" icon="more" slot="right"></mt-button> -->
    </mt-header>
    <!-- </header> -->
</template>
<script>
export default {
    name: 'PageHeader',
    components: {
        
    },
    props: {
        title: {
            type: String,
            default: ''
        },
    },
    data() {
        return {
            
        }
    },
    methods: {
        
    }
}
</script>
<style lang="scss" scoped>
    .header {
        position: relative;
        min-width: 1200px;
        z-index: 100;
        background-color: $white;
    }
</style>
