<template>
    <div class="main my">
        <!-- <page-header :userInfo="userInfo" title="订单详情" :hasMore="false" /> -->
        <div class="my-wrapper">
            <div class="order-process">
                <h3 v-if="[0, 5].includes(orderDetail.orderstatus)" class="order-process-tit">
                <!-- <h3 class="order-process-tit"> -->
                    <i class="iconfont icon-gantan" />
                    <span>该订单会为您保留2.0小时（从下单之日算起），2.0小时之后如果还未付款，系统将自动取消该订单。</span>
                </h3>
            </div>
            <div class="order-info">
                <div class="oi-header">
                    <h3 class="oi-tit">订单信息</h3>
                    <div class="oi-header-tips">{{ orderStatusType[orderDetail.orderstatus] || '已取消' }}</div>
                </div>
                <div class="oi-body">
                    <p class="oi-body-item">订单编号：{{ orderDetail.orderid }}</p>
                    <p class="oi-body-item">下单时间：{{ orderDetail.addtime }}</p>
                    <div class="oi-body-toolbar">
                        <router-link v-if="[0, 3, 5].includes(orderDetail.orderstatus)" class="btn-primary btn-small order-btn" :to="{ path: '/payment', query: { orderid: JSON.stringify([orderDetail.orderid]), money: orderMoney.daifumoney, orderstatus: orderDetail.orderstatus }}">付款</router-link>
                    </div>
                </div>
            </div>
            <div class="order-info">
                <div class="oi-header">
                    <h3 class="oi-tit">商品信息</h3>
                </div>
                <div class="oi-body">
                    <router-link v-for="item in commodity" :key="item.commodity_details_id" class="oi-prod-item" :to="`/product/detail?commodityid=${item.commodityid}&commodity_details_id=${item.commodity_details_id}`">
                        <img :src="item.img" :alt="item.name">
                        <span class="oi-name">
                            <strong class="oi-name-tit">{{ item.title }}</strong>
                            <b class="oi-name-tit">定金（元）: {{ item.type === '2' ? item.money1 : item.money3 }}</b>
                            <b v-if="orderMoney" class="oi-name-tit">全款（元）: {{ orderMoney.allmoney }}</b>
                        </span>
                        <span class="oi-price">
                            <b v-if="orderMoney && item.type === '2'" class="oi-name-tit">尾款（元）: {{ item.money2 }}</b>
                            <b v-if="item.buy_num" class="oi-num">共 {{ item.buy_num }} 件</b>
                        </span>
                    </router-link>
                </div>
            </div>
            <div class="order-info">
                <div class="oi-header">
                    <h3 class="oi-tit">收货信息</h3>
                    <span v-if="orderDetail.yunfeiorder" class="oi-header-tips check-logistic" @click="getKuaidiInfo(orderDetail)">查看物流</span>
                </div>
                <div class="oi-body">
                    <p class="oi-body-item">
                        <span class="oi-addr">{{ orderDetail.takeusername }}</span>
                        <span>{{ orderDetail.takeusermobile }}</span>
                    </p>
                    <p class="oi-body-item">{{ orderDetail.takeuseraddress }}</p>
		    
		    <p class="oi-body-item" v-if="orderDetail.yunfeiorder">快递单号:{{ orderDetail.yunfeiorder }}</p>
		    
                </div>
            </div>
            <!-- <product-list v-if="commodity[0]" class="order-item order-item-prod" :order-head-list="validOrderHeadList" :order-prod-list="commodity" :order-money="orderMoney" /> -->
            <order-price class="order-item order-price" :price-obj="orderMoney" />
        </div>
        <div class="buy-fixed">
            <div class="buy-fixed-btn">
                <!-- orderDetail===9为已取消订单 -->
                <!-- <a v-if="[0, 5].includes(orderDetail.orderstatus)" href="javascript:;" class="btn-text" @click="cancelOrder(orderDetail.orderid)">取消订单</a> -->
                <!-- 20201223.iscancel=1 的时候  有 取消订单按钮 -->
                <a v-if="orderDetail.iscancel===1" href="javascript:;" class="btn-text" @click="cancelOrder(orderDetail.orderid)">取消订单</a>
                <!-- <a href="javascript:;" class="btn-primary btn-dark" @click="cancelOrder(orderDetail.orderid)">取消订单</a> -->
                <span v-if="orderDetail.orderstatus===9" class="btn-text">订单已取消</span>
                <router-link v-if="[0, 3, 5].includes(orderDetail.orderstatus)" class="btn-primary btn-round pay-btn" :to="{ path: '/payment', query: { orderid: JSON.stringify([orderDetail.orderid]), money: orderMoney.daifumoney, orderstatus: orderDetail.orderstatus }}">立即付款</router-link>
                <!-- <router-link class="btn-primary btn-round pay-btn" :to="{ path: '/payment', query: { orderid: JSON.stringify([orderDetail.orderid]), money: orderMoney.daifumoney, orderstatus: orderDetail.orderstatus }}">立即付款</router-link> -->
            </div>
        </div>
        <!-- 查看物流弹窗 -->
        <mt-popup
            class="popup-content"
            v-model="showLogiticPopup"
            position="bottom">
                <div class="logistic-content">
                    <p v-for="(delivery, index) in deliveryList" :key="index">{{ delivery.time + ',' + delivery.context }}</p>
                    <p class="nodata" v-if="!deliveryList[0]">暂无相关物流信息</p>
                </div>
        </mt-popup>
    </div>
</template>
<script>
import pageHeader from '@c/pageHeader'
// import pageFooter from '@c/pageFooter'
import breadcrumbs from '@c/breadcrumbs'
import orderTopInfo from './orderTopInfo.vue'
import productList from './productList.vue'
import orderPrice from './orderPrice.vue'
import mixin from '@c/mixins'
import authMixin from '@c/mixins/auth'
import { myOrderDetail, cancelOrder, getKuaidiInfo } from '@api/my'
// import '../../mock'
export default {
    name: 'AftermarketDetail',
    mixins: [ mixin, authMixin ],
    components: {
        pageHeader,
        // pageFooter,
        breadcrumbs,
        orderTopInfo,
        productList,
        orderPrice
    },
    computed: {
        showBuyBtn() {
            console.log('待付金额', this.orderMoney.daifumoney)
            console.log('订单过期时间', new Date().getTime() - new Date(this.orderDetail.addtime).getTime())
            if([0, '0.00'].includes(this.orderMoney.daifumoney)) { // 待付金额为0不显示付款按钮
                return false
            } else if(new Date().getTime() - new Date(this.orderDetail.addtime).getTime() > 2*60*60*1000) { // 下单超过2小时
                return false
            } else if(this.orderDetail.orderstatus === 9) { // 订单已取消
                return false
            } else {
                return true
            }
        }
    },
    data() {
        return {
            orderDetail: {},
            commodity: [],
            orderMoney: {},
            validOrderHeadList: ['商品', '数量', '定金（元）', '尾款（元）', '总金额（元）'],
            deliveryList: [], // 物流信息列表
            // 0 预订，未付定金  1 预订，未定价 2 预订，已定价（没有资格付钱的那种） 3预订待付尾款  4 预订待发货  5 现货,待付款 6现货,已付款 待发货 7已发货 8已完成 9 已取消
            orderStatusType: [
                '预订，未付定金',
                '预订，未定价',
                '预订，已定价',
                '预订待付尾款',
                '预订待发货',
                '现货,待付款',
                '现货,已付款 待发货',
                '已发货',
                '已完成',
                '已取消'
            ],
            showLogiticPopup: false
        }
    },
    methods: {
        async myOrderDetail(orderid) {
            const res = await myOrderDetail({ orderid })
            console.log('order res', res)
            if (res.code === 1001) {
                this.orderDetail = Object.assign({}, res.list)
                this.commodity = res.list.commodity
                // 添加商品类型('1'.现货，'2'.预订)
                this.commodity.forEach(item => {
                    this.$set(item, 'type', this.orderDetail.type)
                });
                // 现货头部不一样单独写(定金改为定价，没有尾款)
                if(this.commodity[0] && this.commodity[0].type === '1') {
                    this.validOrderHeadList = ['商品', '数量', '定价（元）', '总金额（元）']
                }
                this.orderMoney = Object.assign({}, res.list.ordermoney)
            }
        },
        // 取消订单
        async cancelOrder(orderid) {
            const res = await cancelOrder({ orderid })
            console.log('cancel order res', res)
            const resDetail = res.detail
            if (resDetail && resDetail.status === 1) {
                this.$router.push('/my/order/1')
            }
            this.$notify({ title: '提示', type: resDetail.status === 1 ? 'success' : 'error', message: resDetail.status === 1 ? '订单取消成功' : '订单取消失败', position: 'bottom-right' })
        },
        // 获取快递详细信息
        async getKuaidiInfo(item) {
            console.log('item', item)
            if(!item) return
            this.showLogiticPopup = true
            // this.deliveryList = [
            //     { 
            //         context: '基基材城为瀑为炒淡之日甲方汇票䕩在，基基材城为瀑为炒淡之日甲方汇票䕩在，基基材城为瀑为炒淡之日甲方汇票䕩在，基基材城为瀑为炒淡之日甲方汇票䕩在',
            //         time: '2021-05-08 12:23:55'
            //     },
            //     { 
            //         context: '基基材城为瀑为炒淡之日甲方汇票䕩在在',
            //         time: '2021-05-08 12:23:55'
            //     }
            // ]
            // this.$set(item, 'showPopover', true)
            const res = await getKuaidiInfo({ fareorder: item.yunfeiorder })
            this.deliveryList = res.detail.status === 1 ? res.detail.content : []
            console.log('res', res)
        }
    },
    created() {
        this.getAccount()
    },
    beforeRouteEnter(to, from, next) {
        console.log('from, to', from, to)
        next(vm => {
            console.log('vm.$route.query', vm.$route.query)
            if (vm.$route.query && vm.$route.query.id) {
                vm.myOrderDetail(vm.$route.query.id)
            }
        })
    }
}
</script>
<style lang="scss" scoped>
.order-logo {
    position: relative;
    height: auto;
    padding-bottom: 0;
}
.order-item {
    position: relative;
    // width: 1200px;
    min-height: 120px;
    padding: 10px 16px;
    margin: 10px auto;
    border: none;
    background-color: $white;
    &.order-item-prod {
        padding-bottom: 0;
    }
}
.order-item-row {
    padding: 0;
    height: 222px;
    background-color: transparent;
}
.order-pay {
    float: left;
    width: 285px;
    height: 100%;
    margin-right: 10px;
    padding: 20px 60px;
    background-color: $white;
}
.order-pay-tit {
    margin-bottom: 20px;
    font-weight: bold;
    font-size: 18px;
    text-align: center;
    color: $red;
}
.order-pay-item {
    font-size: 14px;
    color: $light;
}
.order-pay-name {
    @include inlineBlock();
    margin: 0 30px 10px 0;
}
.btn-primary,
.btn-text {
    margin: 10px auto;
    display: block;
    width: 120px;
    text-align: center;
}
span.btn-text {
    color: $light;
}
.order-process {
    // float: left;
    // width: 903px;
    // height: 100%;
    background-color: $white;
}
.order-process-tit {
    display: flex;
    padding: 10px;
    font-weight: normal;
    font-size: $fs14;
    background-color: $lightest;
    > .iconfont {
        flex: 0 0 40px;
        font-size: $fs30;
        color: $blue;
    }
}
.process-list {
    padding: 10px;
    white-space: nowrap;
}
.mainicon {
    display: block;
    margin-bottom: 6px;
    height: 36px;
    line-height: 36px;
}
.icondot {
    position: absolute;
    top: 12px;
    left: 90px;
}
.process-item {
    @include inlineBlock();
    vertical-align: top;
    position: relative;
    margin: 10px 40px 10px 0;
    width: 75px;
    text-align: center;
    white-space: normal;
    color: $dark;
    &:last-child {
        margin-right: 0;
        .icondot {
            display: none;
        }
    }
}
// 现货流程5个
.process-five .process-item {
    margin-right: 120px;
    .icon {
        left: 130px;
    }
    &:last-child {
        margin-right: 0;
    }
}
// 已取消流程2个
.process-two .process-item {
    margin-right: 700px;
    .icon {
        left: 390px;
    }
    &:last-child {
        margin-right: 0;
    }
}
.details-row {
    margin-bottom: 20px;
    &:last-child {
        margin-bottom: 0;
    }
}
.order-details-col {
    @include inlineBlock();
    vertical-align: top;
    // width: 35%;
    line-height: 2;
    color: $light;
    &:nth-child(3) {
        width: 30%;
    }
}
.detail-tit,
.detail-con {
    @include inlineBlock();
    width: 200px;
    vertical-align: top;
}
.detail-link {
    color: $red;
    &:hover {
        color: $reder;
    }
}
.detail-tit {
    width: 120px;
    margin-right: 20px;
    text-align: right;
}
.pop-close {
    position: absolute;
    top: 5px;
    right: 10px;
    cursor: pointer;
    &:hover {
        color: #409efe;
    }
}
.pop-content {
    padding: 5px;
    position: relative;
    font-size: 12px;
    line-height: 1.5;
    max-height: 180px;
    overflow: auto;
    p {
        margin-bottom: 10px;
    }
}
.order-info {
    margin-bottom: 10px;
    padding: 10px 20px;
    background-color: $white;
}
.oi-header {
    display: flex;
    justify-content: space-between;
}
.oi-tit {
    margin-bottom: 10px;
    position: relative;
    padding-left: 10px;
    font-size: $fs16;
    &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 3px;
        height: 70%;
        width: 3px;
        background-color: $red;

    }
}
.oi-header-tips {
    font-size: $fs16;
    color: $red;
    &.check-logistic {
        color: $black;
        font-size: $fs12;
    }
}
.oi-body {
    position: relative;
}
.oi-body-toolbar {
    position: absolute;
    top: 5px;
    right: 0;
    .btn-primary {
        padding: 0 10px;
        width: 60px;
    }
}
.oi-body-item {
    margin-bottom: 5px;
    font-size: $fs14;
    color: $light;
}
.oi-prod-item {
    display: flex;
    color: $black;
    img {
        width: 80px;
        height: 80px;
        border-radius: 6px;
    }
}
.oi-name {
    flex: 1;
    padding: 0 15px;
    font-size: $fs16;
}
.oi-name-tit {
    display: block;
    margin-bottom: 2px;
    font-size: $fs14;
    color: $light;
    &:first-child {
        margin-bottom: 6px;
        font-size: $fs16;
        color: $black;
    }
}
.oi-addr {
    padding-right: 20px;
}
.buy-fixed {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 0 10px;
    height: 55px;
    background-color: $white;
}
.buy-fixed-btn {
    display: flex;
    float: right;
    .btn-text {
        margin-top: 20px;
    }
}
.logistic-content {
    padding: 10px 10px 0;
    font-size: $fs12;
    color: $black;
    > p {
        line-height: 1.5;
        margin-bottom: 10px;
        &.nodata {
            text-align: center;
            color: $light;
        }
    }
}
</style>
