<template>
    <div class="layout layout-info">
        <section class="layout-head">
            <h3 class="layout-title">最新资讯</h3>
            <router-link class="layout-title-toolbar" to="/news">更多 ></router-link>
        </section>
        <section class="info-body">
            <info-list :info-list="list" />
            <!-- <section class="info-more">
                <router-link class="more-btn" to="/news">更多资讯一览</router-link>
            </section> -->
        </section>
    </div>
</template>
<script>
import infoList from '@c/infoList.vue'
export default {
    name: 'LayoutInfo',
    components: {
        infoList
    },
    props: {
        list: {
            type: Array,
            required: true,
            default: null
        }
    }
}
</script>
<style lang="scss" scoped>
    .layout-info {
        width: auto;
        background-color: $white;
    }
    .info-head {
        margin: 0 10px 10px;
        height: 43px;
        // width: 1200px;
        border-bottom: 1px solid $lightest;
    }
    .info-title {
        float: left;
        padding: 10px 0 10px 10px;
        position: relative;
        font-size: 18px;
        &::before {
            content: ' ';
            position: absolute;
            height: 16px;
            width: 2px;
            left: 0;
            top: 13px;
            background-color: $red;
        }
    }
    .info-title-toolbar {
        padding: 13px 10px;
        float: right;
    }
    .info-body {
        // width: 1200px;
        margin: 0 10px;
    }
    .info-more {
        padding: 20px 20px 40px;
        text-align: center;
    }
</style>
