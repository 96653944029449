export default {
    // 获取用户登陆信息
    getUser: () => {
        return !!JSON.parse(sessionStorage.getItem('USER_INFO'))
    },
    userName: () => {
        return localStorage.getItem('USER_ACCOUT')
    },
    getQueryString(name) { 
        var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i"); 
        var r = window.location.search.substr(1).match(reg); 
        if (r != null) return unescape(r[2]); 
        return null; 
    },
    // 清除用户缓存信息(记住的登录信息除外)
    clearUser: () => {
        sessionStorage.clear()
    },
    // 获取出荷时间(unTxt: 没设定出荷时间时使用的文字，默认：未定)
    getPublishString(publishtype, publishyear, publishtime, unTxt = '即将开订') {
        const showTime = publishtime === 'q0' ? `${unTxt}` : publishtime + 0 > 0 ? `${publishtime}月`: publishtime
        return publishtype === '1' || (publishyear === 0 && publishtime === 0) ? unTxt : `${publishyear}年${showTime}`
    },
    // 获取价格显示方式
    getMoneyString(moneytype, dollar, yen, rmb) {
        if (moneytype === '1') {
            return '未定'
        } else {
            const str1 = dollar !== '0.00' ? `${dollar}美元` : yen !== '0.00' ? `${yen}日元` : ''
            const str2 = rmb === '0.00' ? '' : str1 ? `（${rmb}元）` : `${rmb}元`
            return str1 + str2
        }
    },
    // 剩余时间(时分秒)
    formatDuring(mss) {
        // const days = parseInt(mss / (1000 * 60 * 60 * 24));
        const hours = parseInt(mss / (1000 * 60 * 60))
        // const hours = parseInt((mss % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = parseInt((mss % (1000 * 60 * 60)) / (1000 * 60))
        const seconds = Math.round((mss % (1000 * 60)) / 1000)
        return hours + '小时' + minutes + '分钟' + seconds + '秒 '
    },
    getBrowser() {
        const userAgent = navigator.userAgent // 取得浏览器的userAgent字符串
        //是否为移动终端
        if(!!userAgent.match(/MicroMessenger/i)) {
            return 'mobile'
        }
        // 判断是否Opera浏览器
        // if (isOpera) {
        //     return "Opera"
        // };
        // 判断是否Edge浏览器
        if (userAgent.indexOf('Edge') > -1) {
            return 'Edge'
        }
        // 判断是否Firefox浏览器
        if (userAgent.indexOf('Firefox') > -1) {
            return 'FF'
        }
        // 判断是否chorme浏览器
        if (userAgent.indexOf('Chrome') > -1) {
            return 'Chrome'
        }
        // 判断是否Safari浏览器
        if (userAgent.indexOf('Safari') > -1) {
            return 'Safari'
        }
        // 判断是否IE浏览器
        if (userAgent.indexOf('.NET ') > -1) {
            return 'IE'
        }
    }
}
// (new Date()).Format("yyyy-MM-dd hh:mm:ss.S") ==> 2006-07-02 08:09:04.423
Date.prototype.Format = function(fmt) {
    const o = {
        'M+': this.getMonth() + 1, // 月份
        'd+': this.getDate(), // 日
        'h+': this.getHours(), // 小时
        'm+': this.getMinutes(), // 分
        's+': this.getSeconds(), // 秒
        'q+': Math.floor((this.getMonth() + 3) / 3), // 季度
        'S': this.getMilliseconds() // 毫秒
    }
    if (/(y+)/.test(fmt)) { fmt = fmt.replace(RegExp.$1, (this.getFullYear() + '').substr(4 - RegExp.$1.length)) }
    for (const k in o) {
        if (new RegExp('(' + k + ')').test(fmt)) { fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? (o[k]) : (('00' + o[k]).substr(('' + o[k]).length))) }
    }
    return fmt
}
