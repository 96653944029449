<template>
    <div class="main password">
        <!-- <simple-header /> -->
        <!-- <div class="simple-logo">
            <img v-if="step===1" src="@a/img/login-logo.png" width="128" height="50" alt="kamisoul">
            <div v-else class="simple-logo-box">
                <img class="simple-logo-img" src="@a/img/login-logo.png" width="128" height="50" alt="kamisoul">
                <h3 class="simple-title">注册</h3>
            </div>
        </div> -->
        <div class="simple-main">
            <div class="simple-body">
                <!-- 忘记密码表单 -->
                <div class="mobile-select" @click="mobilePopupShow">
                    <div v-if="!mobiletypeItem"> 请选择区号 </div>
                    <div v-if="mobiletypeItem"><span>{{ mobiletypeItem.nation }}</span> <span class="txt"> {{ mobiletypeItem.label }} </span> </div>
                </div>
                <mt-field v-model="ruleForm.username" class="form-item mt-field-phone" label=" " placeholder="手机号码" @keyup.native="ruleForm.username=ruleForm.username.replace(/[^\d]/g, '')" clearable>
                    <!-- <span>获取验证码</span> -->
                </mt-field>
                <mt-field v-model="ruleForm.code" class="form-item mt-field-code" label=" " placeholder="填写验证码" :attr="{ maxlength: 6 }" @keyup.native="ruleForm.code=ruleForm.code.replace(/[^\d]/g, '')" clearable>
                    <a v-if="!countDownDisabled && countDownTime <= 0" class="input-append-btn" href="javascript:;" @click="sendMsg">获取验证码</a>
                    <span v-else>{{ `(${countDownTime})` }}</span>
                </mt-field>
                <mt-field v-model="ruleForm.password" class="form-item mt-field-code" label=" " placeholder="请输入新密码" type="password" />
                <a href="javascript:;" class="btn-primary block" @click="repassword">提交</a>
            </div>
        </div>
        <!-- <simple-footer /> -->
        <mt-popup
            v-model="popupVisible"
            position="bottom"
        >
            <div class="mobiletype-list">
                <ul>
                    <li v-for="item in mobiletypes" :key="item.mobiletype" :class="{'selected':item.selected}" @click="mobiletypeSelect(item.mobiletype)">
                        <div class="inner">
                            <span>{{ item.nation }}</span>
                            <span class="txt">{{ item.label }}</span>
                        </div>
                    </li>
                </ul>
            </div>
        </mt-popup>
    </div>
</template>
<script>
// @c an alias to /compontents
import mixin from '@c/mixins'
// import simpleHeader from '@c/simpleHeader.vue'
// import simpleFooter from '@c/simpleFooter.vue'
import { sendMsg, getMobileType, repassword, logout } from '@api'
export default {
    name: 'Password',
    components: {
        // simpleHeader,
        // simpleFooter
    },
    mixins: [mixin],
    data() {
        return {
            step: 1,
            popupVisible: false,
            ruleForm: {
                mobiletype: 1, // ''=>请选择区号 1 中国大陆 2香港 3澳门 4台湾
                username: '',
                code: '',
                password: ''
            },
            timer: null,
            countDownTime: 0,
            countDownDisabled: false,
            mobiletypes: [],
            mobiletypeItem: null
        }
    },
    created() {
        this.getMobileTypes()
        // 获取上次倒计时时间
        const currentTime = new Date().getTime()
        const lastTime = +localStorage.getItem('COUNTDOWN_FORGET_PASSWORD')
        this.countDownTime = Math.floor(60 - (currentTime - lastTime) / 1000)
        console.log('countDownTime', this.countDownTime)
        this.countDown('COUNTDOWN_FORGET_PASSWORD')
    },
    // 实例销毁前清除定时器
    beforeDestroy() {
        // 更新缓存
        if (this.timer) window.clearInterval(this.timer)
    },
    // 退出路由前清除定时器
    beforeRouteLeave(to, from, next) {
        // 导航离开该组件的对应路由时调用
        // 可以访问组件实例 `this`
        if (this.timer) window.clearInterval(this.timer)
        next()
    },
    methods: {
        async getMobileTypes() {
            const res = await getMobileType()
            if (res.code === 1001) {
                // console.log(res.detail)
                res.detail.forEach(item => {
                    item['selected'] = false
                })
                this.mobiletypes = res.detail
                const cacheMobiletype = localStorage.getItem('USER_MOBILETYPE')
                // console.log(cacheMobiletype)
                if (cacheMobiletype) {
                    this.mobiletypeSelect(parseInt(cacheMobiletype))
                } else {
                    this.mobiletypeSelect(1) // 默认选中中国区
                }
            }
        },
        // mobiletype 选择
        mobiletypeSelect(mobiletype) {
            // const idx = this.mobiletypes.findIndex(item => item.mobiletype === mobiletype)
            // console.log(idx)
            // this.mobiletypes[idx].selected = true
            this.mobiletypes.forEach(item => {
                item.selected = false
                if (item.mobiletype === mobiletype) {
                    item.selected = !item.selected
                    this.ruleForm.mobiletype = item.mobiletype
                    this.mobiletypeItem = item
                    // console.log(this.ruleForm)
                    this.mobilePopupHide()
                }
            })
        },
        mobilePopupShow() {
            this.popupVisible = true
        },
        mobilePopupHide() {
            this.popupVisible = false
        },
        // 发送短信
        async sendMsg() {
            const res = await sendMsg({
                mobiletype: this.ruleForm.mobiletype,
                mobile: this.ruleForm.username,
                type: 3
            })
            console.log('res', res)
            const msg = [
                '',
                '发送成功',
                '发送失败',
                '该手机号已注册',
                '60秒内不能多次发送验证码'
            ]
            const status = res.detail.status
            // this.$notify({ title: '提示', type: status === 1 ? 'success' : 'error', message: msg[status] || '未知错误', position: 'bottom-right' })
            this.$toast(msg[status] || '未知错误')
            if (status !== 1) {
                // 重设时间
                this.countDownTime = 0
                this.countDownDisabled = false
                // 清除定时器
                if (this.timer) window.clearInterval(this.timer)
            } else {
                // 重设时间
                this.countDownTime = this.countDownTimeMax
                // 记录当前时间更新缓存
                localStorage.setItem('COUNTDOWN_LOGIN', new Date().getTime())
                // 开始倒计时
                this.countDown('COUNTDOWN_FORGET_PASSWORD')
            }
        },
        async logout() {
            const res = await logout()
            if (res.detail.status === '1') {
                this.isLogin = false
                this.$utils.clearUser()
                this.$router.push('/login')
            }
            // this.$notify({ title: '提示', type: res.detail.status === '1' ? 'success' : 'error', message: res.msg, position: 'bottom-right' })
            this.$toast(res.msg)
        },
        async repassword() {
            const res = await repassword(this.ruleForm)
            console.log('res', res)
            const status = res.detail.status
            // this.$notify({ title: '提示', type: status === '1' ? 'success' : 'error', message: res.msg, position: 'bottom-right' })
            this.$toast(res.msg)
            if (status === '1') this.logout()
        }
    }
}
</script>
<style lang="scss" scoped>
.main.password {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $white;
}
.form-item {
    margin-bottom: 10px;
    /deep/ {
        .mint-cell-title {
            width: 30px;
            padding: 10px;
            background: url(../../assets/img/icon/phone.png) left center no-repeat;
            background-size: contain;
        }
    }
}
.mt-field-code {
    /deep/ {
        .mint-cell-title {
            width: 30px;
            padding: 10px;
            background: url(../../assets/img/icon/key.png) left center no-repeat;
            background-size: contain;
        }
    }
}
.mobile-select{
    width: 100%;margin-bottom: 10px;
    background: url(../../assets/img/icon/area.png) 8px 50% no-repeat #fff;
    background-size: 18px 18px;
    line-height: 44px;padding: 0 20px 0 45px;
    border-bottom: 1px #f1f1f1 solid;
    box-sizing: border-box;
    font-size: 18px;
    color: #333;
    position: relative;
    .txt{
        color: #999;
    }
    &:after{
        content: '';
        background: url(../../assets/img/icon/arrow-down.png) no-repeat center;
        background-size: 14px auto;
        width: 16px;height: 16px;display: block;
        position: absolute;
        right: 10px;top: 50%;transform: translateY(-50%);
    }
}

.input-append-btn {
    padding-left: 10px;
    border-left: 1px solid $lighter;
    color: $red;
}
.btn-primary.block {
    margin: 20px 0;
}
.mobiletype-list{
    width: 720px;max-height:400px;
    padding: 20px 0;
    overflow: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    li{
        padding: 8px 20px;
        font-size: 16px;color:#333;
        display: flex;
        justify-content: center;
        .inner{
            width: 160px;
            display: flex;
            justify-content: space-between;
        }
        span{
            display: inline-block;padding:0 10px;
        }
        .txt{
            color: #999;
        }
        &.selected{
            color: $reder;
            .txt{
                color:$reder
            }
        }
    }
}
</style>
