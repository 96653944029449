<template>
    <div class="main aftermarket-success">
        <section class="afs-item afs-result">
            <h3 class="afs-title">售后申请提交成功！</h3>
            <p class="afs-txt">客服将在2~3个工作日内进行售后审核，请耐心等候</p>
            <p class="afs-txt afs-tips">{{ secondTime }}秒后自动返回网站首页</p>
            <router-link to="/" class="btn-primary btn-plain">返回首页</router-link>
            <router-link to="/my/aftermarket/list" class="btn-primary">查看售后订单</router-link>
        </section>
    </div>
</template>
<script>
export default {
    name: 'AftermarketSuccess',
    components: {
    },
    data() {
        return {
            secondTime: 15,
            timer: null
        }
    },
    created() {
        this.countDown()
    },
    methods: {
        countDown() {
            const self = this
            this.timer = window.setInterval(() => {
                if (self.secondTime > 0) {
                    self.secondTime--
                } else {
                    window.clearInterval(self.timer)
                    self.$router.push('/')
                }
            }, 1000)
        }
    },
    beforeRouteLeave(to, from, next) {
        window.clearInterval(this.timer)
        next()
    }
}
</script>
<style lang="scss" scoped>
.afs-result{
    text-align: center;padding-top: 50px;
    .btn-primary{
        width: 80%;margin: 0 auto;
        display: block;
        box-sizing: border-box;
        margin-bottom: 20px;
        height: 40px;
        line-height: 38px;
    }
}
.afs-title {
    margin-bottom: 10px;
    font-size: 18px;
}
.afs-txt {
    margin-bottom: 10px;
    color: $light;
}
.afs-tips {
    color: $red;padding-bottom: 30px;
}
</style>
