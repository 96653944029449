<template>
    <div class="my-home">
        <!-- <page-header :userInfo="userInfo" title="我的" /> -->
        <router-link class="my-user" :to="userInfo?'/my/accout':'login'">
            <div v-if="userInfo" class="mu-info">
                <img class="mu-img" :src="userInfo.headimgurl || headImg" alt="">
                <span class="mu-name">
                    <strong>{{ userInfo.username }}</strong>
                    <b>K点 {{ userInfo.integral }}</b>
                </span>
            </div>
            <div v-else class="mu-info">
                <img class="mu-img" :src="headImg" alt="">
                <span class="mu-login">登录</span>
            </div>
            <i class="iconfont icon-jiantou"></i>
        </router-link>
        <section v-if="userInfo" class="my-layout my-number">
            <!-- <router-link class="head-num-item" to="/my/order/3">
                <i class="head-num-num">{{ userInfo.daifukuan || 0 }}</i>待付款
            </router-link> -->
            <!-- <router-link class="head-num-item" to="/my/order/4">
                <i class="head-num-num">{{ userInfo.daifahuo || 0 }}</i>待发货
            </router-link> -->
            <router-link class="head-num-item" to="/my/order/3">
                <!-- <i class="head-num-num">{{ isuseIntergal }}</i>我的K点 -->
                <i class="head-num-num">{{ userInfo.daibukuan }}</i>待补款
            </router-link>
            <router-link class="head-num-item" to="/my/follow">
                <i class="head-num-num">{{ infoList.length }}</i>我的关注
            </router-link>
            <router-link class="head-num-item" to="/my/message">
                <i class="head-num-num">{{ +userInfo.news > 99 ? `${userInfo.news}+` : userInfo.news }}</i>我的消息
            </router-link>
        </section>
        <section v-if="userInfo" class="my-layout my-order">
            <div class="ml-header">
                <span>我的订单</span>
                <div class="ml-header-toolbar">
                    <router-link class="ml-header-link" to="/my/order/1">查看全部<i class="iconfont icon-jiantou"></i></router-link>
                </div>
            </div>
            <div class="ml-body mo-list">
                <router-link class="icon-wrapper" to="/my/order/3">
                    <i class="icon-num">{{ userInfo.daibukuan }}</i>
                    <i class="iconfont icon-qiandai"></i>
                    <b class="icon-text">待补款</b>
                </router-link>
                <router-link class="icon-wrapper" to="/my/order/3">
                    <i class="icon-num">{{ userInfo.daifukuan }}</i>
                    <i class="iconfont icon-qianbao"></i>
                    <b class="icon-text">待付款</b>
                </router-link>
                <router-link class="icon-wrapper" to="/my/order/4">
                    <i class="icon-num">{{ userInfo.daifahuo }}</i>
                    <i class="iconfont icon-huoche"></i>
                    <b class="icon-text">待发货</b>
                </router-link>
                <router-link class="icon-wrapper" to="/my/order/5">
                    <i class="icon-num">{{ userInfo.yiwancheng }}</i>
                    <i class="iconfont icon-yiwancheng"></i>
                    <b class="icon-text">已完成</b>
                </router-link>
                <router-link class="icon-wrapper" to="/my/aftermarket/list">
                    <i class="icon-num">{{ userInfo.wodeshouhou }}</i>
                    <i class="iconfont icon-kefu"></i>
                    <b class="icon-text">我的售后</b>
                </router-link>
            </div>
        </section>
        <section class="my-layout my-about" :class="{ 'go-top': !userInfo }">
            <div class="ml-header">关于我们</div>
            <div class="ml-body">
                <img :src="require('@a/img/about1.png')" alt="">
                <p class="ml-txt">“kamisoul”为魔构科技旗下模型品牌，该品牌主要生产各类高端可动人偶系列，主要以C端产品为主要发展方向，致力于打造全球最高端的模型涉及、制作、生产品牌。</p>
            </div>
        </section>
        <div v-if="userInfo" class="btn-wrapper">
            <a href="javascript:;" class="btn-primary" @click="logout">退出登录</a>
        </div>
        <!-- <page-footer key="/my" path="/my" /> -->
    </div>
</template>
<script>
import pageHeader from '@c/pageHeader'
import pageFooter from '@c/pageFooter'
// import infoList from '@c/infoList.vue'
import { logout } from '@api'
import { userCollection } from '@api/my'
import { getUserIntegral } from '@api/order'
// import './mock'
export default {
    name: 'MyHome',
    components: {
        pageHeader,
        pageFooter,
        // myMainHead,
        // infoList
    },
    props: {
        userInfo: {
            type: Object
        }
    },
    data() {
        return {
            headImg: require('@a/img/my-head.jpg'),
            infoList: [],
            isuseIntergal: 0,
        }
    },
    created() {
        // 获取关注列表
        this.userCollection()
        this.getUserIntegral()
    },
    methods: {
        async userCollection() {
            const res = await userCollection()
            // console.log('userCollection res', res)
            // const list = res.list
            if (res && res.code === 1001) {
                this.infoList = res.list ? res.list.list : []
            }
        },
        async getUserIntegral() {
            const res = await getUserIntegral()
            if (res && res.detail) {
                // 缓存登录信息
                // this.userInfoObj = Object.assign({}, res.detail)
                // console.log('userInfoObj1', this.userInfoObj)
                this.isuseIntergal = res.detail.integral
            }
        },
        async logout() {
            const res = await logout()
            if (res.detail.status === '1') {
                // console.log(222222)
                // this.isLogin = false
                this.$utils.clearUser()
                this.$toast('登出成功')
                location.reload()
            }
            // this.$notify({ title: '提示', type: res.detail.status === '1' ? 'success' : 'error', message: res.msg, position: 'bottom-right' })
            // this.$message({ type: res.detail.status === '1' ? 'success' : 'error', message: res.msg })
        }
    }
}
</script>
<style lang="scss" scoped>
.my-home{
    padding-bottom: 50px;
}
.my-user {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 30px 30px 70px 50px;
    background: url(../../assets/img/my-bg.jpg) center top no-repeat;
    .iconfont {
        font-size: $fs36;
        color: $white;
    }
}
.mu-info {
    display: flex;
    align-items: center;
    height: 70px;
}
.mu-img {
    margin-right: 20px;
    height: 100%;
    border-radius: 50%;
}
.mu-name {
    display: flex;
    flex-direction: column;
    font-size: $fs18;
    color: $white;
    strong,
    b {
        padding: 8px 0;
    }
    strong {
        font-size: $fs24;
    }
}
.mu-login {
    font-size: $fs24;
    color: $white;
}
.my-layout {
    padding: 12px;
    margin: 15px;
    background-color: $white;
    border-radius: 6px;
}
.ml-header {
    display: flex;
    justify-content: space-between;
    position: relative;
    padding: 0 10px;
    margin-bottom: 10px;
    height: 18px;
    font-size: $fs18;
    &::before {
        content: "";
        position: absolute;
        top: 2px;
        left: 0;
        width: 3px;
        height: 16px;
        background-color: $red;
    }
}
.ml-header-toolbar {
    margin-top: -3px;
}
.ml-header-link {
    padding: 3px 3px 3px 10px;
    border-radius: 10px;
    color: $white;
    background-color: $red;
    font-size: $fs12;
    .iconfont {
        vertical-align: -2px;
    }
}
.ml-body {
    padding: 20px;
    font-size: $fs14;
    color: $dark;
}
.my-about {
    .ml-body {
        img {
            display: block;
            margin: 0 auto 20px;
            width: 180px;
        }
    }
    &.go-top {
        margin-top: -30px;
    }
}
.my-number {
    margin-top: -40px;
    display: flex;
}
.head-num-item {
    flex: 1;
    padding: 5px 10px;
    text-align: center;
    border-right: 1px solid $lighter;
    color: $dark;
    &:last-child {
        border-right: none;
    }
}
.head-num-num {
    display: block;
    margin-bottom: 5px;
    font-size: $fs24;
    color: $black;
}
.mo-list {
    display: flex;
    padding: 20px 0;
}
.icon-wrapper {
    position: relative;
    flex: 1;
    display: flex;
    align-items: center;
    flex-direction: column;
    position: relative;
    .iconfont {
        height: 46px;
        font-size: $fs36;
        color: $red;
    }
}
.icon-text {
    font-size: $fs12;
    color: $dark;
}
.icon-num {
    position: absolute;
    top: -3px;
    right: 10px;
    width: 20px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    border-radius: 50%;
    color: $white;
    background-color: $red;
}
.btn-wrapper {
    .btn-primary {
        border-radius: 6px;
    }
}
</style>
