<template>
    <div id="app" ref="main" class="wrapper">
        <router-view />
        <page-footer />
    </div>
</template>
<script>
import pageFooter from '@c/pageFooter.vue'
export default {
    name: 'App',
    components: {
        pageFooter
    },
}
</script>
<style lang="scss">
    .nodata{
        height: 200px;
        text-align: center;
        color: #9d9c9c;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .txt-grey{color: #999 !important;}
</style>
