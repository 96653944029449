<template>
    <div class="main aftermarket">
        <!-- <page-header :userInfo="userInfo" showMore="false" title="售后申请" /> -->
        <!-- <simple-header :userInfo="userInfo" /> -->
        <!-- <top /> -->
        <!-- <product-list v-if="commodity[0]" class="aftermarket-item" :aftermarket-head-list="validOrderHeadList" :order-money="orderMoney" :aftermarket-prod-list="commodity" /> -->
        <!-- <mt-field class="form-item mt-field-phone" label="问题描述" :attr="{ maxlength: 16 }" v-model="form.description" clearable /> -->
        <!-- <section class="pro-item">
            <div class="thumb"><img :src="orderDetail.imgthumbnail" :alt="orderDetail.title"></div>
            <div class="infor">
                <h4 class="name"> {{ orderDetail.title }}</h4>
                <p>出荷时间： {{ parseInt(orderDetail.publishtype) === 1 ? '未定' : `${orderDetail.publishyear}年${orderDetail.publishtime}` }}</p>
                <p class="price"> 定价: {{ parseInt(orderDetail.moneytype) === 1 ?'未定价':`${orderDetail.rmb}元` }} </p>
            </div>
        </section> -->
        <aftermarket-pro-item :aftermarket-pro-detail="orderDetail" />
        <div class="form-item">
            <h3 class="tit">
                <span>订单截图</span>
                <small>支持bmp,gif,jpg,png,jpeg格式文件，大小不超过5M</small>
            </h3>
            <el-upload
                class="upload-my"
                accept="image/png,image/jpeg,image/gif"
                :class="{ 'upload-disabled': orderimgUploadDisabled }"
                action="/aftermarket/imgUpload"
                list-type="picture-card"
                :data="uploadData"
                :on-remove="orderimgRemove"
                :on-change="orderimgChange"
                :on-success="orderimgHandleSuccess"
                :before-upload="beforeLimitImgSizeUpload"
            >
                <i class="el-icon-plus" /><span class="txt">上传图片</span>
            </el-upload>
            <!-- <update-file ref="orderUpload" :multiple="true" :type="1" :max="1" :list="orderImglist" :img-list-params="orderImglistParams" @getOrderImgUrl="getOrderImgUrl" /> -->
        </div>
        <div class="form-item">
            <h3 class="tit">
                <span>购买渠道</span>
            </h3>
            <mt-field v-model="form.orderchannel" label="" placeholder="请填写购买渠道" clearable />
        </div>
        <div class="form-item">
            <h3 class="tit">
                <span>订单编号</span>
                <small>同一个订单编号只能申请一次售后，请妥善保管好自己的订单编号</small>
            </h3>
            <mt-field v-model="form.orderid" label="" placeholder="请填写订单编号" clearable />
        </div>
        <div class="form-item">
            <h3 class="tit">
                <span>问题描述</span>
                <small>请您在此描述问题</small>
            </h3>
            <!-- <mt-field v-model="form.description" type="textarea" rows="5" /> -->
            <textarea v-model="form.description" type="textarea" rows="6" class="textarea" />
            <!-- <update-file ref="imgUpload" :multiple="true" :type="0" :max="5" :list="imgList" :img-list-params="imgListParams" @getImgUrl="getImgUrl" /> -->
            <el-upload
                class="upload-my"
                accept="image/png,image/jpeg,image/gif"
                :multiple="isMultiple"
                :limit="5"
                :class="{ 'upload-disabled': uploadDisabled }"
                action="/aftermarket/imgUpload"
                list-type="picture-card"
                :data="uploadData"
                :on-remove="handleRemove"
                :on-change="handleChange"
                :on-success="handleSuccess"
                :before-upload="beforeLimitImgSizeUpload"
            >
                <i class="el-icon-plus" /><span class="txt">上传图片</span>
            </el-upload>
            <p class="form-tips">一次最多可上传5张图片，每张图片大小不超过5M，支持bmp,gif,jpg,png,jpeg格式文件</p>
        </div>
        <div class="fixed-bot">
            <a href="javascript:void(0);" class="btn-primary" :class="{'disabled':isDisabled }" @click="aftermarketAdd">提交</a>
        </div>
        <!-- <simple-footer /> -->
    </div>
</template>
<script>
import aftermarketProItem from '../proItem.vue'
import { aftermarketAdd, getInformationDetail } from '@api/aftermarket'
import mixin from '@c/mixins'
import authMixin from '@c/mixins/auth'
export default {
    name: 'AftermarketCommit',
    components: {
        aftermarketProItem
    },
    mixins: [mixin, authMixin],
    data() {
        return {
            isMultiple: true,
            form: {
                informationid: '',
                orderid: '',
                orderchannel: '',
                orderimg: '',
                description: ''
            },
            imgFileList: [],
            uploadData: { frames: 1, files: null },
            orderimgUploadDisabled: false,
            uploadDisabled: false,
            orderDetail: {}
        }
    },
    computed: {
        isDisabled() {
            return this.form.orderimg === '' || this.form.orderid === '' || this.form.orderchannel === '' || (this.form.description === '' && this.imgFileList.length === 0)
        }
    },
    created() {
        this.getAccount()
    },
    methods: {
        // async myOrderDetail(orderid) {
        //     const res = await myOrderDetail({ orderid })
        //     console.log('order res', res)
        //     if (res.code === 1001) {
        //         this.orderDetail = Object.assign({}, res.list)
        //         this.commodity = res.list ? res.list.commodity : []
        //         this.orderMoney = Object.assign({}, res.list ? res.list.ordermoney : {})
        //     }
        // },
        // 3售后提交,必传: orderid，commodity_details_id，type（1维修 2补件 3退货 4换货）
        // 可传: desc（描述），img（图片 多个用丨号分隔）
        async getInformationDetail(params) {
            const res = await getInformationDetail(params)
            if (res.code === 1001) {
                this.orderDetail = res.detail
                // console.log(this.orderDetail)
            }
        },
        // async aftermarketAdd() {
        //     console.log('this.$refs.upload.list', this.$refs.upload.list)
        //     this.form.imgFiles = this.$refs.upload.list.join('|')
        //     console.log('form', this.form)
        //     const res = await aftermarketAdd(this.form)
        //     console.log('aftermarketAdd res', res)
        //     if (res.code === 1001) {
        //         this.$router.push('/my/aftermarket-success')
        //     }
        // },
        async aftermarketAdd() {
            if (this.isDisabled) return // 订单图片和描述文字不填，提交按钮点击无效
            console.log('imgFileList', this.imgFileList)
            if (this.imgFileList.length > 0) {
                this.form.img = this.imgFileList.join('|')
                console.log('form inmlist', this.form.img)
            }
            console.log('form', this.form)
            const res = await aftermarketAdd(this.form)
            console.log('aftermarketAdd res', res)
            if (res.code === 1001) {
                this.$router.push('/my/aftermarket-success')
            }
            if (res.code === 1004) {
                this.$messageBox(res.msg)
            }
        },
        handleChange(file, fileList) {
            // console.log(file, fileList)
            this.uploadData.files = file.raw
            // this.form.imgFiles = fileList.map(item => item.raw)
            // console.log('imgFiles', this.form.imgFiles)
            if (fileList.length >= 5) {
                this.uploadDisabled = true
            }
        },
        handleSuccess(response) {
            // console.log('response', response)
            this.imgFileList.push(response.detail.img)
            console.log('imgFileList', this.imgFileList)
        },
        handleRemove(file, fileList) {
            console.log(file.response.detail.img)
            console.log(fileList)
            if (fileList.length >= 5) {
                this.uploadDisabled = true
            } else {
                this.uploadDisabled = false
            }
            // this.form.imgFiles = fileList.map(item => item.raw)
            const idx = this.imgFileList.findIndex(item => item === file.response.detail.img)
            this.imgFileList.splice(idx, 1)
            // console.log(idx)
            // console.log('imgFileList', this.imgFileList)
        },
        orderimgChange(file, fileList) {
            // console.log(file, fileList)
            this.uploadData.files = file.raw
            // this.form.imgFiles = fileList.map(item => item.raw)
            // console.log('orderimg', this.form.orderimg)
            if (fileList.length >= 1) {
                this.orderimgUploadDisabled = true
            }
        },
        orderimgHandleSuccess(response) {
            // console.log('response', response)
            this.form.orderimg = response.detail.img
            console.log('orderimg', this.form.orderimg)
        },
        orderimgRemove(file, fileList) {
            // console.log(file, fileList)
            this.form.orderimg = '' // 清空数据
            if (fileList.length >= 1) {
                this.orderimgUploadDisabled = true
            } else {
                this.orderimgUploadDisabled = false
            }
            // this.form.imgFiles = fileList.map(item => item.raw)
            console.log('orderimg', this.form.orderimg)
        },
        beforeLimitImgSizeUpload(file) {
            const isLt5M = file.size / 1024 / 1024 < 5
            if (!isLt5M) {
                this.$messageBox('图片大小不能超过5MB!')
            }
            return isLt5M
        }
    },
    beforeRouteEnter(to, from, next) {
        console.log('from, to', from, to)
        next(vm => {
            const queryData = vm.$route.query
            // console.log(queryData)
            if (queryData && queryData.informationid) {
                vm.form.informationid = queryData.informationid // id
                vm.getInformationDetail(queryData)
            }
        })
    }
}
</script>
<style lang="scss" scoped>
.mint-field{
    border:1px solid #f1f1f1;
}
.mint-cell{
    min-height: 40px;
}
.aftermarket{
    padding-bottom: 70px;
}
.aftermarket-item {
    position: relative;
    // width: 1200px;
    min-height: 120px;
    padding: 20px;
    margin: 10px auto;
    border: none;
    background-color: $white;
    &.mt20 {
        margin-top: 20px;
    }
}
.upload-my{
    padding: 5px 0;
}
.form-item {
    padding: 15px 16px;
    margin-top: 12px;
    margin-bottom: 12px;
    background-color: $white;
    .tit {
        font-size: $fs16;margin-bottom:10px;
        small {
            padding: 0 5px;
            color: $light;
            font-weight: normal;
            font-size: $fs12;
        }
    }
}
.textarea{
    box-sizing: border-box;margin-bottom: 5px;
    width: 100%;
    border: 1px #f1f1f1 solid;
    outline: none;
    padding: 10px;
}
.form-tips{
    padding-top: 3px;
    color: #999;font-size: 10px;
}
.fixed-bot{
    position: fixed;left: 0;right: 0;bottom: 0;
    padding:15px 16px;
    background: #fff;
    .btn-primary{
        width: 100%;height:42px;line-height: 40px;box-sizing: border-box;
        &.disabled{
            background: #cdcdcd;border-color: #cdcdcd;
        }
    }
}
</style>
