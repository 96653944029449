
// @c an alias to /compontents
// import breadcrumbs from '@c/breadcrumbs'
// import mySideHead from './mySideHead'
// import mySideMenu from './mySideMenu'
// import sideInformation from '@c/sideInformation.vue'
import mixin from '@c/mixins'
import authMixin from '@c/mixins/auth'
import { getLikeCommodity } from '@api/my'
export default {
    name: 'My',
    mixins: [mixin, authMixin],
    data() {
        return {
            title: '你可能喜欢',
            information: null
        }
    },
    computed: {
        breadcrumbsData() {
            console.log('router', this.$route)
            const arr = [{ name: '个人中心', url: path === '/my' ? '' : '/my' }]
            const path = this.$route.fullPath
            if (path !== '/my') {
                arr.push({ name: this.$route.meta.title, url: '' })
            }
            console.log('arr', arr)
            return arr
        }
    },
    created() {
        this.getAccount()
        this.getLikeCommodity()
    },
    methods: {
        async getLikeCommodity() {
            const res = await getLikeCommodity()
            if (res && res.detail) {
                this.information = Object.assign({}, res.detail)
            }
        }
    }
}
