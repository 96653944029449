
// @c an alias to /compontents
import pageHeader from '@c/pageHeader.vue'
import pageFooter from '@c/pageFooter.vue'
import productList from '@c/productList.vue'
import infoList from '@c/infoList.vue'
import { getSeries } from '@api'
import { getCommodityList } from '@api/product'
import { getInformationList } from '@api/news'
import mixin from '@c/mixins'
import authMixin from '@c/mixins/auth'
import '@a/scss/theme/index.css'
import Vue from 'vue'
import { Select, Option } from 'element-ui';
Vue.component(Select.name, Select);
Vue.component(Option.name, Option);
export default {
    name: 'Product',
    mixins: [ mixin, authMixin ],
    components: {
        pageHeader,
        pageFooter,
        productList,
        infoList,
    },
    watch: {
        '$route'(val) {
            this.title = val.query.title || ''
            this.getData()
        },
        searchType(val) {
            this.getData()
        },
    },
    data() {
        return {
            // type: '1', // 0: 全部，1:预订，2:现货
            productList: [],
            seriesList: [],
            total: 0,
            currentPage: 1,
            seriesid: '',
            sex: 0,
            title: '',
            searchType: 1,
            // keyWord: '',
            infoList: []
        }
    },
    methods: {
        // 获取筛选下拉
        async getSeries() {
            const res = await getSeries()
            this.seriesList = res.detail
        },
        // 获取列表
        async getData() {
            this.searchType === 1 ? 
            this.getProductList()
            : this.getInfoList()
        },
        async getProductList() {
            const res = await getCommodityList({
                seriesid: this.seriesid,
                sex: this.sex,
                type: this.type,
                ordertype: this.ordertype,
                page: this.currentPage,
                title: this.title
            })
            if (res && res.list) {
                // this.productList = res.list.list.slice(0, 4)
                this.productList = res.list.list
                this.total = res.list.total
            }
        },
        // 获取资讯列表
        async getInfoList() {
            const res = await getInformationList({
                title: this.title,
                seriesid: this.seriesid,
                sex: this.sex,
                page: this.currentPage
            })
            this.infoList = res.list.list
            this.total = res.list.total
        },
    },
    created() {
        this.getSeries()
        this.getAccount()
    },
    beforeRouteEnter(to, from, next) {
        console.log('to, from', to, from)
        next((vm) => {
            vm.title = to.query && to.query.title ? to.query.title : ''
            vm.getData()
        })
    }
}
