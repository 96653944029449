
// @c an alias to /compontents
import pageHeader from '@c/pageHeader.vue'
import pageFooter from '@c/pageFooter.vue'
import homeBanner from './homeBanner.vue'
import layoutInfo from './layoutInfo.vue'
import layoutProd from './layoutProd.vue'
import layoutAbout from './layoutAbout.vue'
import mixin from '@c/mixins'
import authMixin from '@c/mixins/auth'
import { homeIndex, isGetOpenid } from '@api'
export default {
    name: 'Home',
    mixins: [mixin, authMixin],
    components: {
        pageHeader,
        pageFooter,
        homeBanner,
        layoutInfo,
        layoutProd,
        layoutAbout
    },
    data() {
        return {
            banner: [],
            commodity: [],
            information: []
        }
    },
    methods: {
        // 获取首页数据
        async getData() {
            const res = await homeIndex()
            // this.banners = res.list.list
            Object.keys(res.detail).forEach(key => {
                this[key] = res.detail[key]
            })
            // 如里>10,只取10个
            // if (this.commodity.length > 10) this.commodity = this.commodity.slice(0, 10)
            console.log('this.commodity', this.commodity)
        },
        // 回跳：redirect_uri/?code=CODE&state=STATE
        async handleOpenid() {
            const res = await isGetOpenid()
            console.log('res', res)
            const resDetail = res.detail
            if (res.code === 1001 && resDetail && resDetail.status === 2) {
                const redirectUri = encodeURIComponent('http://m.renmnet.com/#/')
                const appid = resDetail.appid || 111
                // location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${redirectUri}&response_type=code&scope=SCOPE&state=STATE#wechat_redirect`
                location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${redirectUri}&connect_redirect=1&response_type=code&scope=snsapi_base&state=STATE`
            }
        }
    },
    created() {
        this.getAccount()
        this.getData()
    },
    beforeRouteEnter(to, from, next) {
        console.log('to, from', to, from)
        next((vm) => {
            console.log('from.query',  from.query)
            console.log('to.query', to.query)
        })
    }
}
