<template>
    <div class="my-order">
        <!-- <page-header :user-info="userInfo" title="我的售后" /> -->
        <div class="my-body">
            <ul class="aftermarket-list">
                <li v-for="item in aftermarkedtList" :key="item.id">
                    <router-link :to="`/my/aftermarket-detail?aftermarketorderid=${item.aftermarketorderid}`">
                        <div class="thumb"><img :src="item.imgthumbnail" alt=""></div>
                        <div class="infor">
                            <h4 class="name"> {{ item.title }}</h4>
                            <p class="date">
                                <span>{{ item.addtime.substring(0,11) }}</span> |
                                <span> 售后单号：{{ item.aftermarketorderid }} </span>
                            </p>
                            <p class="status"> {{ aftermarketStatus[item.status-1] }} </p>
                        </div>
                    </router-link>
                </li>
                <li v-if="aftermarkedtList.length === 0" class="nodata">暂无数据</li>
            </ul>
        </div>

        <div class="bot-link">
            <router-link to="/my/aftermarket/information">我要申请售后</router-link>
        </div>

    </div>
</template>
<script>
import { getAftermarketList } from '@api/aftermarket'
// import '../mock'
export default {
    name: 'MyAftermarket',
    props: {
    },
    data() {
        return {
            aftermarketStatus: [
                ' 待审核 ',
                ' 审核已通过 ',
                ' 审核未通过 ',
                ' 售后完成 ',
                ' 售后已取消 ',
                ' 资料待填写 ',
                ' 待售后完成 '
            ],
            aftermarkedtList: []
        }
    },
    watch: {

    },
    created() {
        this.getAftermarketList()
    },
    methods: {
        async getAftermarketList() {
            const res = await getAftermarketList()
            console.log('aftermarketList res', res)
            if (res.code === 1001) {
                this.aftermarkedtList = res.list.list
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.my-title {
    margin-bottom: 20px;
    font-weight: normal;
    font-size: 18px;
}
.my-body {
    position: relative;
}
.aftermarket-list{
    padding-bottom: 60px;
    li{
        min-height: 110px; background: #fff; margin-bottom: 15px; position: relative;
        a{
            display: block;width:100%;min-height: 110px; padding:15px 20px;
        }
        .thumb{
            width:80px;height:80px;position: absolute;top: 15px;left: 20px; overflow: hidden;
            img{
                width: 100%;
            }
        }
        .infor{
           padding-left: 90px;color: #999;
           .name{
               font-size: 14px;color: #202020;padding-bottom: 10px;
               overflow: hidden;text-overflow: ellipsis;white-space: nowrap;
           }
           .date{
               font-size: 10px;
           }
           .status{
               position: absolute;left: 110px;bottom: 15px;
           }
        }
    }
}
.bot-link{
    position: fixed;left: 0;right: 0;bottom: 0;z-index: 99;
    background: #fff;
    padding: 12px 20px;
    a{
        background: #ff0000;font-size: 16px;line-height: 40px; color: #fff;display: block;text-align: center;
        border-radius: 6px;
    }
}
</style>
