<template>
    <ul class="info-list">
        <li v-for="item in infoList" :key="item.informationid" class="info-item">
            <span class="info-price-item info-item-date">
                <!-- 出荷时间： -->
                {{ $utils.getPublishString(item.publishtype, item.publishyear, item.publishtime) }}
            </span>
            <router-link class="info-link" :to="`/news/detail?id=${item.informationid}`">
                <img class="info-img" :src="item.img" :alt="item.title">
                <span class="info-block">
                    <span class="info-price">
                        <span class="info-name text-ellipsis">{{ item.title }}</span>
                        <span class="info-price-item">￥{{ item.moneytype === '2' ? `${item.rmb}元` : '未定' }}</span>
                    </span>
                    <i v-if="showMoreIcon" class="iconfont icon-arrow-round-r" />
                </span>
            </router-link>
            <!-- <p class="info-series">
                <span class="info-series-name">{{ item.seriestitle }}</span>
                <span class="info-series-date">{{ item.ontime || item.updatetime }} <i class="icon icon-more" /></span>
            </p>
            <span v-if="item.type" class="info-type" :class="[ `info-type-${item.type - 1}` ]">
                {{ [ '可预订', '未出货', '有现货' ][item.type - 1] }}
            </span> -->
        </li>
    </ul>
</template>
<script>
export default {
    name: 'InfoList',
    props: {
        showMoreIcon: {
            type: Boolean,
            default: true
        },
        infoList: {
            type: Array,
            required: true,
            default: null
        }
    }
}
</script>
<style lang="scss" scoped>
    .info-item {
        position: relative;
        display: inline-block;
        vertical-align: top;
        width: calc(50% - 5px);
        margin: 0 0 15px 0;
        background-color: $white;
        &:nth-child(2n+1) {
            margin-right: 10px;
        }
    }
    .info-item-date {
        margin-bottom: 5px;
    }
    .info-link {
        display: block;
        border: 1px solid $lighter;
    }
    .info-img {
        display: block;
        width: 100%;
        height: 180px;
        // @include widthHeight(285px, 285px);
        object-fit: cover;
    }
    .info-block {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        padding: 5px 10px;
        display: block;
        justify-content: space-between;
        align-items: center;
        background-color: rgba(255, 255, 255, .5);
        .iconfont {
            @include inlineBlock();
            font-size: $fs20;
            color: $redgray;
        }
    }
    .info-price {
        // flex: 0 0 calc(100% - 30px);
        @include inlineBlock();
        width: calc(100% - 20px);
    }
    .info-name {
        // padding: 5px;
        display: block;
        width: calc(100% - 10px);
        height: 20px;
        line-height: 18px;
        font-size: $fs14;
        color: $black;
    }
    .info-price-item {
        // padding: 5px 10px;
        display: block;
        color: $dark;
    }
    // .info-series {
    //     height: 47px;
    //     padding: 16px 10px;
    //     text-align: justify;
    // }
    // .info-series-date {
    //     float: right;
    //     .icon {
    //         margin-top: -1px;
    //     }
    // }
    // 类型
    // .info-type {
    //     position: absolute;
    //     top: 0;
    //     left: 10px;
    //     width: 60px;
    //     height: 35px;
    //     line-height: 35px;
    //     text-align: center;
    //     border-radius: 0 0 5px 5px;
    //     color: $white;
    //     background-color: $red;
    // }
    // .info-type-1 {
    //     background-color: $yellow;
    // }
    // .info-type-2 {
    //     background-color: $green;
    // }
    // 一行3个
    // .col-three {
    //     .info-item {
    //         &:nth-child(4n) {
    //             margin-right: 20px;
    //         }
    //         &:nth-child(3n) {
    //             margin-right: 0;
    //         }
    //     }
    // }
</style>
