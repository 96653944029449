<template>
    <div class="form-wrapper">
    	<mt-field v-model="ruleForm.faremoney" class="form-item" label="快递费用"  clearable placeholder="请如实填写,如有异常运费不予返还" />
	<mt-field v-model="ruleForm.fareaccount" class="form-item" label="收款账号"  clearable placeholder="仅限支付宝收款" />
        <mt-field v-model="ruleForm.fareorder" class="form-item" label="配送单号" :attr="{ maxlength: 16 }" clearable @change="getcomcode" @keyup.native="ruleForm.fareorder=ruleForm.fareorder.replace(/[^\d\w]/g, '')" placeholder="不支持快递到付,请勿发到付件"/>
        <mt-field v-model="ruleForm.comname" class="form-item" label="快递公司" disabled :attr="{ maxlength: 11 }" clearable />
	<mt-field v-model="ruleForm.username" class="form-item" label="收件人" :attr="{ maxlength: 11 }" clearable @keyup.native="ruleForm.username=ruleForm.username.replace(/\s+/g, '')" placeholder="请与支付宝收款账号一致"/>
	<mt-field v-model="ruleForm.mobile" class="form-item" label="联系方式" :attr="{ maxlength: 11 }" clearable @keyup.native="ruleForm.mobile=ruleForm.mobile.replace(/[^\d]/g, '')" />

        <mt-cell class="form-item" title="寄回区域" is-link :value="ruleForm.address" @click.native="showAreaPopup=true" />

        <mt-field v-model="ruleForm.desc" class="form-item" label="详细地址" type="textarea" :attr="{ maxlength: 200 }" rows="3" @keyup.native="addressDesc=addressDesc.replace(/\s+/g, '')" />
       
        
        <section class="btn-wrapper">
            <a href="javascript:;" class="btn-primary btn-plain" @click="$emit('close')">取消</a>
            <a href="javascript:;" class="btn-primary btn-round" :class="{'disabled':isDisabled}" @click="onSubmit">保存</a>
        </section>
        <mt-popup
            v-model="showAreaPopup"
            class="popup-content"
            position="bottom"
        >
            <province-city-area @setFullAddress="getFullAddress" @close="showAreaPopup=false" />
        </mt-popup>
    </div>
</template>

<script>
import provinceCityArea from '@c/provinceCityArea'
import { getcomcode, addAftermarketDetail } from '@api/aftermarket'
import mixin from '@c/mixins'
export default {
    name: 'AddSetDialog',
    components: {
        provinceCityArea
    },
    mixins: [mixin],
    props: {
        visible: {
            type: Boolean,
            default: false
        },
        aftermarketid: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            ruleForm: {
                type: 2,
                fareorder: '',
		faremoney: '',
		fareaccount: '',
                comcode: '',
                comname: '',
                address: '',
                username: '',
                mobile: ''
            },
            addressDesc: '',
            showAreaPopup: false
        }
    },
    computed: {
        isDisabled() {
            return this.ruleForm.fareorder === '' || this.ruleForm.address === '' || this.ruleForm.desc === '' || this.ruleForm.username === '' || this.ruleForm.mobile === ''
        }
    },
    watch: {
        visible(val) {
            console.log('ruleForm', this.ruleForm)
            if (val) {
                this.$refs.ruleForm && this.$refs.ruleForm.resetFields()
                for (const key in this.ruleForm) {
                    this.ruleForm[key] = ''
                }
                this.addressDesc = ''
                this.ruleForm.type = 2
            }
        }
    },
    methods: {
        // 4305957043934 韵达快递
        async getcomcode(fareorder) {
            // 请求查询快递公司，如果查不到则单号有问题
            const res = await getcomcode({ fareorder: fareorder })
            const detail = res.detail
            if (detail && detail.status !== 1) {
                // this.$notify({ title: '提示', type: 'warning', message: '快递查询失败，请检查快递单号是否正确！', position: 'bottom-right' })
                this.$toast('快递查询失败，请检查快递单号是否正确！')
            } else {
                this.ruleForm.comcode = detail.company[0].code
                this.ruleForm.comname = detail.company[0].name
            }
        },
        getFullAddress(addressObj) {
            console.log('full address', addressObj)
            this.ruleForm.address = addressObj.province.name + addressObj.city.name + addressObj.area.name
        },
        async onSubmit() {
            if (this.isDisabled) return
            console.log('form', this.ruleForm)
            this.ruleForm.address += this.addressDesc
            const res = await addAftermarketDetail({ aftermarketid: this.aftermarketid, ...this.ruleForm })
            console.log('addAftermarketDetail res', res)
            const resDetail = res.detail
            const resStatus = resDetail.status
            this.$toast(resStatus === 1 ? '操作成功' : resDetail.msg)
            if (resStatus === 1) {
                this.$emit('getData')
                this.$emit('update:visible', false)
                this.$emit('close')
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.form-wrapper {
    padding: 0 10px 10px;
    background-color: $white;
    /deep/ {
        .mint-cell-title {
            width: 80px;
            color: $dark;
        }
        .mint-cell {
            border-bottom: 1px solid $lightest;
        }
    }
}
.btn-wrapper {
    margin: 20px 0;
    padding: 0 20px;
    .btn-primary {
        display: inline-block;
        width: calc(50% - 5px);
        &.disabled{
            background: #d4d4d4;border-color: #d4d4d4;
        }
    }
}
</style>
