import Vue from 'vue'
import router from './router'
import Utils from '@a/js/utils'
import querystring from 'querystring'
import store from './store'
import App from './App.vue'
// 请求拦截
import $http from '@api/http'

import '@/assets/icon/iconfont.js'; // 阿里矢量图, svg
import '@/assets/icon/iconfont.css'; // 阿里iconfont

// 按需引入ElementUI
// import '@a/js/initElementUI'
import './assets/scss/_reset.scss'
import '@a/js/initMint'
import './assets/scss/main.scss'
import { Toast } from 'mint-ui'
import { MessageBox } from 'mint-ui'
import 'element-ui/lib/theme-chalk/index.css'
import { Upload, Icon } from 'element-ui'
Vue.use(Upload)
Vue.use(Icon)
// import './mock'
// vue bus
import VueBus from '@a/js/vueBus'
Vue.use(VueBus)
Vue.config.productionTip = false

const addMethod = {
    $utils: Utils,
    $qs: querystring,
    $toast: Toast,
    $messageBox: MessageBox,
    $http: $http,
    $bus: new Vue(), // 事件总线bus
    processEnv: process.env.NODE_ENV // 环境变量
}
Vue.prototype = Object.assign(Vue.prototype, addMethod)

router.beforeEach(async(to, from, next) => {
    // console.log('from, to', from, to)
    /* 路由发生变化修改页面title */
    if (to.meta.title) {
        document.title = to.meta.title
    }
    next()
})

new Vue({
    router,
    store,
    mounted() {
        document.dispatchEvent(new Event('render-event'))
    },
    render: h => h(App)
}).$mount('#app')
