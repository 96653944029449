<template>
    <div class="order-info">
        <div class="oi-header">
            <h3 class="oi-tit">商品信息</h3>
        </div>
        <div class="oi-body">
            <router-link v-for="item in orderProdList" :key="item.commodity_details_id" class="oi-prod-item" :to="`/product/detail?commodityid=${item.commodityid}&commodity_details_id=${item.commodity_details_id}`">
                <img :src="item.img" :alt="item.name">
                <span class="oi-name">
                    <strong class="oi-name-tit">{{ item.title }}</strong>
                    <b class="oi-name-tit">{{ item.type === '2' ? '定金（元）': '定价（元）' }}: {{ item.type === '2' ? item.deposit : item.money }}</b>
                    <b class="oi-name-tit">总金额（元）: {{ item.moneyall }}</b>
                    <b v-if="item.type === '2'" class="oi-name-tit">尾款（元）: {{ item.money2 }}</b>
                    <b class="oi-name-tit">{{ reason[item.nstatus - 2] }}</b>
                </span>
                <span class="oi-price">
                    <b class="oi-num">共 {{ item.count }} 件</b>
                </span>
            </router-link>
        </div>
    </div>
</template>
<script>
export default {
    name: 'OrderProdList',
    props: {
        // orderHeadList: {
        //     type: Array,
        //     default: null,
        //     required: true
        // },
        orderProdList: {
            type: Array,
            default: null,
            required: true
        }
    },
    data() {
        return {
            // localOrderHeadList: [],
            reason: ['库存不足', '限购已满', '已截单', '已下架'] // 失效原因
        }
    }
}
</script>
<style lang="scss" scoped>
.oi-header-tips {
    font-size: $fs16;
    color: $red;
}
.oi-body-item {
    margin-bottom: 5px;
    font-size: $fs14;
    color: $light;
}
.oi-prod-item {
    display: flex;
    color: $black;
    img {
        width: 80px;
        height: 80px;
        border-radius: 6px;
    }
}
.oi-name {
    flex: 1;
    padding: 0 15px;
    font-size: $fs16;
}
.oi-name-tit {
    display: block;
    margin-bottom: 2px;
    font-size: $fs12;
    color: $light;
    &:first-child {
        margin-bottom: 6px;
        font-size: $fs16;
        color: $black;
    }
}
</style>
