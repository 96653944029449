<template>
    <div>
        <div class="fileCon">
            <div class="list">
                <div v-for="(item, index) in list" :key="index">
                    <div class="close" @click="close(index)">x</div>
                    <img :src="item" />
                </div>
                <div v-show="maxStatus" class="add" @click="chooseType">
                    <div class="add-image"><i class="icon">+</i> <span class="txt"> 上传图片 </span> </div>
                </div>
            </div>
        </div>
        <input
            id="upload_file"
            type="file"
            class="file-input"
            accept="image/png,image/jpeg,image/jpg"
            :multiple="multiple"
            style="display: none"
            @change="inputChange"
        >
    </div>
</template>
<script>
import Http from '@/api/http'
export default {
    props: {
        multiple: Boolean,
        // eslint-disable-next-line vue/require-default-prop
        max: Number,
        // eslint-disable-next-line vue/require-default-prop
        list: Array,
        // eslint-disable-next-line vue/require-default-prop
        imgListParams: Array,
        // eslint-disable-next-line vue/require-default-prop
        type: Number
    },
    data() {
        return {
            maxStatus: true
        }
    },
    methods: {
        chooseType() {
            document.getElementById('upload_file').click()
        },
        close(index) {
            this.list.splice(index, 1)
            this.maxStatus = this.list !== this.max
        },
        async inputChange(e) {
            const files = e.target.files
            const len = this.list.length + files.length
            if (len > this.max) {
                document.getElementById('upload_file').value = ''
                this.$ui.toast(`最多允许上传${this.max}张`)
                return
            }
            const uploadAll = [].slice.call(files, 0).map(this.upload)
            // 使用object.values(files)，测试安卓存在兼容性问题，替换为[].slice.call(files ,0)

            //   this.$ui.loading.open({//上传中效果，可自行替换。
            //     text: "上传中...",
            //     spinnerType: "fading-circle"
            //   });
            this.$toast('上传中...')
            const result = await Promise.all(uploadAll)
            document.getElementById('upload_file').value = ''
            //   this.$ui.loading.close();
        },
        upload(file, index) {
            return new Promise(async(resolve, reject) => {
                const form = new FormData()
                form.append('files', file)
                form.append('frames', 1) // 根据上传入参添加参数
                console.log('form', form)
                const result = await Http({
                    url: '/aftermarket/imgUpload',
                    method: 'post',
                    data: form
                })
                if (result.code !== 1001) {
                    // 失败处理
                    //   this.$ui.toast(`第${index + 1}张(${file.name})上传出错(已忽略)`);
                    resolve(result)
                    return
                }
                console.log(this.type)
                // this.imgListParams.push(result.detail.img) // 相对路径的图片，提交需要的字段参数
                // this.list.push(result.detail.imgurl) // 直接修改数据不可取
                if (this.type === 0) {
                    this.$emit('getImgUrl', result.detail.imgurl) // 描述图片列表显示
                }
                if (this.type === 1) {
                    this.$emit('getOrderImgUrl', result.detail.imgurl) // 订单图片缩略图显示
                }
                if (this.list.length === this.max) {
                    this.maxStatus = false
                }
                resolve(result)
            })
        }
    }
}
</script>

 <style lang="scss" scoped>
.fileCon {
  width: 100%;
  min-height: 100px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  .list {
    width: 100%;
    min-height: 100px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    & > div {
      width: 80px;
      height: 80px;
      margin: 10px 10px 10px 0;
      position: relative;
      & > img {
        width: 100%;
        height: 100%;
      }
      .close {
        width: 15px;
        height: 15px;
        // background-image: url(/images/icon_close.png);
        // background-size: 100%;
        border: 1px solid $lighter;
        border-radius: 50%;
        line-height: 12px;
        font-size: $fs12;
        text-align: center;
        background-color: $white;
        font-family: Helvetica;
        position: absolute;
        bottom: 1px;
        right: 1px;
        color: $light;
      }
    }
  }
}
.add-image {
  width: 80px;
  height: 80px;
  background: #f2f2f2;
  text-align: center;
  color: #f6969c;
  .icon {
    font-size: $fs36
  }
  .txt{
    display: block;font-size: 12px;
  }
  //   background-image: url(/images/addImg.png);
  //   background-size: 100%;
}
</style>
