<template>
    <div>
        <div class="form-item">
            <h3 class="tit">
                <span>问题描述</span>
                <small>请您在此描述问题</small>
            </h3>
            <!-- <mt-field v-model="ruleForm.desc" type="textarea" rows="5" /> -->
            <textarea v-model="ruleForm.desc" type="textarea" rows="5" class="textarea" />
            <!-- <update-file ref="upload" :multiple="true" :max="5" :list="imgList" :img-list-params="imgListParams" /> -->
            <el-upload
                class="upload-my"
                accept="image/png,image/jpeg,image/gif"
                :multiple="isMultiple"
                :limit="5"
                :class="{ 'upload-disabled': uploadDisabled }"
                action="/aftermarket/imgUpload"
                list-type="picture-card"
                :data="uploadData"
                :on-remove="handleRemove"
                :on-change="handleChange"
                :on-success="handleSuccess"
                :before-upload="beforeLimitImgSizeUpload"
            >
                <i class="el-icon-plus" /><span class="txt">上传图片</span>
            </el-upload>
            <p class="form-tips">一次最多可上传5张图片，每张图片大小不超过5M，支持bmp,gif,jpg,png,jpeg格式文件</p>
        </div>
        <div class="btn-wrapper">
            <a href="javascript:;" class="btn-primary btn-plain" @click="$emit('close')">取消</a>
            <a href="javascript:;" class="btn-primary btn-block btn-round btn-submit" :class="{'disabled':isDisabled}" @click="onSubmit">提交</a>
        </div>
    </div>
</template>

<script>
import { addAftermarketDetail } from '@api/aftermarket'
import mixin from '@c/mixins'
export default {
    name: 'AddDesDialog',
    components: { },
    mixins: [mixin],
    props: {
        visible: {
            type: Boolean,
            default: false
        },
        aftermarketid: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            isMultiple: true,
            ruleForm: {
                desc: '',
                img: '',
                type: 1
            },
            uploadDisabled: false,
            imgFileList: [],
            uploadData: { frames: 1, files: null }
        }
    },
    computed: {
        isDisabled() {
            return this.ruleForm.desc === '' && this.imgFileList.length === 0
        }
    },
    watch: {
        visible(val) {
            if (val) {
                this.$refs.ruleForm && this.$refs.ruleForm.resetFields()
                for (const key in this.ruleForm) {
                    this.ruleForm[key] = ''
                }
                this.ruleForm['type'] = 1
                this.uploadDisabled = false
            } else {
                this.uploadData.files = null
                this.imgFileList = []
                this.imgListParams = []
                this.$refs.upload && this.$refs.upload.clearFiles()
            }
        }
    },
    methods: {
        handleChange(file, fileList) {
            // console.log(file, fileList)
            this.uploadData.files = file.raw
            // this.form.imgFiles = fileList.map(item => item.raw)
            // console.log('imgFiles', this.form.imgFiles)
            if (fileList.length >= 5) {
                this.uploadDisabled = true
            }
        },
        handleSuccess(response) {
            // console.log('response', response)
            this.imgFileList.push(response.detail.img)
            console.log('imgFileList', this.imgFileList)
        },
        handleRemove(file, fileList) {
            console.log(file.response.detail.img)
            console.log(fileList)
            if (fileList.length >= 5) {
                this.uploadDisabled = true
            } else {
                this.uploadDisabled = false
            }
            // this.form.imgFiles = fileList.map(item => item.raw)
            const idx = this.imgFileList.findIndex(item => item === file.response.detail.img)
            this.imgFileList.splice(idx, 1)
            // console.log(idx)
            // console.log('imgFileList', this.imgFileList)
        },
        beforeLimitImgSizeUpload(file) {
            const isLt5M = file.size / 1024 / 1024 < 5
            if (!isLt5M) {
                this.$messageBox('图片大小不能超过5MB!')
            }
            return isLt5M
        },
        async onSubmit() {
            if (this.isDisabled) return
            this.ruleForm.img = this.imgFileList.join('|')
            console.log(this.ruleForm.img)
            const res = await addAftermarketDetail({ aftermarketid: this.aftermarketid, ...this.ruleForm })
            console.log('addAftermarketDetail res', res)
            const resDetail = res.detail
            const resStatus = resDetail ? resDetail.status : 1
            // this.$notify({ title: '提示', type: resStatus === 1 ? 'success' : 'warning', message: resStatus === 1 ? '操作成功' : resDetail.msg, position: 'bottom-right' })
            this.$toast(resStatus === 1 ? '操作成功' : resDetail.msg)
            this.$emit('getData')
            this.$emit('close')
        }
    }
}
</script>
<style lang="scss" scoped>
.form-item {
    padding: 10px 16px;
    margin-bottom: 12px;
    background-color: $white;
    .tit {
        font-size: $fs16;margin-bottom:10px;
        small {
            padding: 0 5px;
            color: $light;
            font-weight: normal;
            font-size: $fs12;
        }
    }
}
.form-tips {
    word-break: break-all;padding-top: 10px;
    color: $light;
}
.btn-wrapper {
    padding: 0 20px;
    margin: 20px 0;
    .btn-primary {
        display: inline-block;
        width: calc(50% - 5px);
        &.disabled{
            background: #d4d4d4;border-color: #d4d4d4;
        }
    }
}
.textarea{
    box-sizing: border-box;margin-bottom: 8px;
    width: 100%;
    border: 1px #f1f1f1 solid;
    outline: none;
    padding: 10px;
}
</style>
