import mixin from '@c/mixins'
import simpleHeader from '@c/simpleHeader.vue'
import simpleFooter from '@c/simpleFooter.vue'
import { sendMsg, register } from '@api'
export default {
    name: 'Register',
    mixins: [mixin],
    components: {
        simpleHeader,
        simpleFooter
    },
    data() {
        return {
            ruleForm: {
                mobile: '',
                password: '',
                code: ''
            },
            timer: null,
            countDownTime: 0,
            countDownDisabled: false
        }
    },
    created() {
        // 获取上次倒计时时间
        const currentTime = new Date().getTime()
        const lastTime = +localStorage.getItem('COUNTDOWN_REGISTER')
        this.countDownTime = Math.floor(60 - (currentTime - lastTime) / 1000)
        console.log('countDownTime', this.countDownTime)
        this.countDown('COUNTDOWN_REGISTER')
    },
    // 实例销毁前清除定时器
    beforeDestroy() {
        // 记录当前时间更新缓存
        // localStorage.setItem('COUNTDOWN_REGISTER', new Date().getTime())
        // 更新缓存
        if (this.timer) window.clearInterval(this.timer)
    },
    // 退出路由前清除定时器
    beforeRouteLeave(to, from, next) {
        // 导航离开该组件的对应路由时调用
        // 可以访问组件实例 `this`
        if (this.timer) window.clearInterval(this.timer)
        // 记录当前时间更新缓存
        // localStorage.setItem('COUNTDOWN_REGISTER', new Date().getTime())
        next()
    },
    methods: {
        // 发送短信
        async sendMsg() {
            this.$refs.ruleForm.validateField('mobile', async error => {
                console.log(error)
                if (!error) {
                    const res = await sendMsg({
                        mobile: this.ruleForm.mobile,
                        type: 2
                    })
                    console.log('res', res)
                    const status = res.detail.status
                    const msg = [
                        '',
                        '发送成功',
                        '发送失败',
                        '该手机号已注册',
                        '60秒内不能多次发送验证码'
                    ]
                    // this.$notify({ title: '提示', type: status === 1 ? 'success' : 'error', message: msg[status] || '未知错误', position: 'bottom-right' })
                    this.$message({
                        message: msg[status] || '未知错误',
                        type: status === 1 ? 'success' : 'error'
                    })
                    if (status !== 1) {
                        // 重设时间
                        this.countDownTime = 0
                        this.countDownDisabled = false
                        // 清除定时器
                        if (this.timer) window.clearInterval(this.timer)
                    } else {
                        // 重设时间
                        this.countDownTime = this.countDownTimeMax
                        // 记录当前时间更新缓存
                        localStorage.setItem('COUNTDOWN_LOGIN', new Date().getTime())
                        // 开始倒计时
                        this.countDown('COUNTDOWN_REGISTER')
                    }
                }
            })
        },
        async onSubmit() {
            this.$refs['ruleForm'].validate(async valid => {
                if (valid) {
                    const res = await register(this.ruleForm)
                    // {"code":1001,"msg":"登录成功","detail":{"status":"1"}}
                    // const res = {
                    //     "code": 1001,
                    //     "msg": "",
                    //     "detail": {
                    //         "status": 1 //1成功
                    //     }
                    // };
                    console.log('res', res)
                    const msg = [
                        '',
                        '注册成功',
                        '该手机号已注册',
                        '验证码无效',
                        '验证码过期失效',
                        '验证码无效'
                    ]
                    const status = res.detail.status
                    console.log('status', status)
                    // this.$notify({ title: '提示', type: status === 1 ? 'success' : 'error', message: res.msg || msg[status], position: 'bottom-right' })
                    this.$message({
                        message: msg[status] || res.msg,
                        type: status === '1' ? 'success' : 'error'
                    })
                    if (status === '1') { // 注册成功
                        // this.$router.push('/login')
                        const redirectUrl = this.$route.query.redirectUrl
                        debugger
                        if (redirectUrl) {
                            this.$router.push(redirectUrl)
                        } else {
                            this.$router.push('/')
                        }
                        this.$refs['ruleForm'].resetFields()
                    } else if(status === '2') { // 手机号已注册
                        this.$refs['ruleForm'].resetFields()
                    } else { // 验证码过期、失效，只需要清空验证码
                        this.ruleForm.code = ''
                    }
                }
            })
        }
    }
}
