<template>
    <div class="pca-wrapper">
        <div class="pca-item pca-p">
            <el-select v-model="provincecode" placeholder="省" filterable @change="getCity">
                <el-option
                    v-for="item in provinceList"
                    :key="item.code"
                    :label="item.name"
                    :value="item.code"
                />
            </el-select>
        </div>
        <div class="pca-item pca-p">
            <el-select v-model="citycode" placeholder="市" filterable @change="getArea">
                <el-option
                    v-for="item in cityList"
                    :key="item.code"
                    :label="item.name"
                    :value="item.code"
                />
            </el-select>
        </div>
        <div class="pca-item pca-p">
            <el-select v-model="areacode" placeholder="区" filterable @change="setArea">
                <el-option
                    v-for="item in areaList"
                    :key="item.code"
                    :label="item.name"
                    :value="item.code"
                />
            </el-select>
        </div>
        <section class="btn-wrapper">
            <a href="javascript:;" class="btn-primary btn-plain" @click="$emit('close')">取消</a>
            <a href="javascript:;" class="btn-primary" @click="onConfirm">确定</a>
        </section>
        <!-- <mt-picker :slots="slots" value-key="name" @change="onValuesChange"></mt-picker> -->
    </div>
</template>
<script>
import '@a/scss/theme/index.css'
import Vue from 'vue'
import { Select, Option } from 'element-ui'
Vue.component(Select.name, Select)
Vue.component(Option.name, Option)
import { getProvince, getCity, getArea } from '@api/my'
export default {
    name: 'ProvinceCityArea',
    props: {
        provincecodeDefault: {
            type: String,
            default: ''
        },
        citycodeDefault: {
            type: String,
            default: ''
        },
        areacodeDefault: {
            type: String,
            default: ''
        },
    },
    watch: {
        provincecodeDefault(val, oldVal) {
            if (val) this.setProvince()
        }
    },
    created() {
        this.getProvince()
    },
    data() {
        return {
            provincecode: this.provincecodeDefault,
            provinceName: '',
            citycode: this.citycodeDefault,
            cityName: '',
            areacode: this.areacodeDefault,
            areaName: '',
            provinceList: [],
            cityList: [],
            areaList: [],
            addressObj: null,
            // slots: [
            //     {
            //         flex: 1,
            //         // values: ['2015-01', '2015-02', '2015-03', '2015-04', '2015-05', '2015-06'],
            //         values: [],
            //         className: 'slot1',
            //         // textAlign: 'right'
            //     }, {
            //         divider: true,
            //         content: '-',
            //         className: 'slot2'
            //     }, {
            //         flex: 1,
            //         // values: ['2015-01', '2015-02', '2015-03', '2015-04', '2015-05', '2015-06'],
            //         values: [],
            //         className: 'slot3',
            //         // textAlign: 'left'
            //     }, {
            //         divider: true,
            //         content: '-',
            //         className: 'slot2'
            //     }, {
            //         flex: 1,
            //         // values: ['2015-01', '2015-02', '2015-03', '2015-04', '2015-05', '2015-06'],
            //         values: [],
            //         className: 'slot5',
            //         // textAlign: 'left'
            //     }
            // ]
        }
    },
    methods: {
        // onValuesChange(picker, values) {
        //     // console.log('values', values[0].code, values[1].code, values[2].code)
        //     console.log('provincecode', values[0].code, this.provincecode)
        //     console.log('citycode', values[1].code, this.citycode)
        //     console.log('areacode', values[2].code, this.areacode)
        //     // console.log('getSlotValue', picker.getSlotValue())
        //     if(values[0] && values[1] && values[2]) {
        //         this.provincecode = values[0].code
        //         this.citycode = values[1].code
        //         this.areacode = values[2].code
        //     }
        //     // if (values[0] > values[1]) {
        //     //     picker.setSlotValue(1, values[0]);
        //     // }
        // },
        // 获取省
        async getProvince() {
            console.log(this.provincecodeDefault, this.citycodeDefault, this.areacodeDefault)
            const res = await getProvince()
            this.provinceList = res.detail
            if (this.provinceList[0]) {
                this.setProvince()
            }
        },
        setProvince() {
            if (this.provinceList[0]) {
                const provinceObj = this.provinceList.find(item => item.code === this.provincecodeDefault) || this.provinceList[0]
                this.provincecode = provinceObj.code
                this.provinceName = provinceObj.name
                this.getCity()
            }
        },
        // 获取市
        async getCity() {
            if (!this.provincecode) {
                // this.$notify({ title: '提示', type: 'warning', message: '请先选择省', position: 'bottom-right' })
                this.$toast('请先选择省')
                return
            }
            const provinceObj = this.provinceList.find(item => item.code === this.provincecode)
            // this.provincecode = provinceObj.code
            this.provinceName = provinceObj.name
            const res = await getCity({ provincecode: this.provincecode })
            this.cityList = res.detail
            // console.log(this.cityList)
            // this.slots[2].values = this.cityList
            if (this.cityList[0]) {
                const cityObj = this.cityList.find(item => item.code === this.citycodeDefault) || this.cityList[0]
                this.citycode = cityObj.code
                this.cityName = cityObj.name
                console.log('01-cityName:' + this.cityName)
                this.getArea()
            }
        },
        // 获取区
        async getArea() {
            if (!this.citycode) {
                // this.$notify({ title: '提示', type: 'warning', message: '请先选择市', position: 'bottom-right' })
                this.$toast('请先选择市')
                return
            }
            const res = await getArea({ citycode: this.citycode })
            // console.log(this.citycode)
            // console.log(this.byCodeGetCity(this.citycode))
            this.cityName = this.byCodeGetCity(this.citycode) // 重新对城市名称赋值  
            // console.log(this.cityName)
            this.areaList = res.detail
            // this.slots[4].values = this.areaList
            // if (this.areaList[0]) {
            //     const areaObj = this.areaList.find(item => item.code === this.areacodeDefault) || this.areaList[0]
            //     this.areacode = areaObj.code
            //     this.areaName = areaObj.name
            //     // this.setAddress(this.areacode)
            // }
            this.setArea(this.areacodeDefault)
        },
        setArea(areacode) {
            if (this.areaList[0]) {
                const areaObj = this.areaList.find(item => item.code === areacode) || this.areaList[0]
                this.areacode = areaObj.code
                this.areaName = areaObj.name
            }
        },
        byCodeGetCity(code) {
            return this.cityList.filter(item => item.code === code)[0].name
        },
        setAddress(areacode) {
            console.log('areacode', areacode)
            console.log('provinceName', this.provinceName)
            console.log('cityName', this.cityName)
            console.log('areaName', this.areaName)
            this.addressObj = Object.assign({}, this.addressObj, {
                province: {
                    name: this.provinceName,
                    code: this.provincecode
                },
                city: {
                    name: this.cityName,
                    code: this.citycode
                },
                area: {
                    name: this.areaName,
                    code: this.areacode
                }
            })
            console.log('addressObj', this.addressObj)
            // this.$emit('setFullAddress', this.addressObj)
        },
        onConfirm() {
            this.setAddress()
            this.$emit('setFullAddress', this.addressObj)
            this.$emit('close')
        }
        // reset() {
        //     this.provincecode = ''
        //     this.citycode = ''
        //     this.areacode = ''
        // }
    }
}
</script>
<style lang="scss" scoped>
.pca-wrapper {
    padding: 20px;
    display: inline-block;
    width: 100%;
    height: 260px;
    /deep/ {
        .picker-item {
            font-size: $fs12;
        }
    }
}
.pca-item {
    // @include inlineBlock();
    // width: calc(100% / 3);
    margin-bottom: 20px;
    /deep/ {
        .el-select {
            width: 100%;
        }
    }
}
.btn-wrapper {
    margin: 20px 0;
    .btn-primary {
        display: inline-block;
        width: calc(50% - 5px);
    }
}
</style>
