<template>
    <div class="select-list">
        <h3>请选择当前可申请售后的产品</h3>
        <ul class="info-list">
            <li v-for="item in informationList" :key="item.informationid" class="info-item" :class="{'selected':item.selected}" @click="aftermarketSelected(item.informationid)">
                <a href="javascript:void(0)" class="information-item">
                    <div ref="thumb" class="thumb">
                        <img class="info-img" :src="item.img" :alt="item.title">
                    </div>
                    <h4 class="info-name">{{ item.title }}</h4>
                    <p class="info-price">
                        <span class="info-price-item">
                            出荷时间：
                            <!-- {{ item.publishtype === '1' ? '未定' : (`${item.publishyear}年` + (item.publishtime === 'q0' ? '未定' : item.publishtime)) }} -->
                            {{ $utils.getPublishString(item.publishtype, item.publishyear, item.publishtime) }}
                        </span>
                        <span class="info-price-item">定价：{{ item.moneytype === '1' ? '未定' : `${item.rmb}元` }}</span>
                    </p>
                </a>
            </li>
        </ul>
        <div class="dialog-box" :class="{show:dialogVisible}">
            <div class="inner">
                <div class="dialog-header">提示</div>
                <div class="dialog-cont">
                    <div class="tips-box">
                        <h4>您的本月售后申请次数异常！</h4>
                        <p>您申请售后次数异常，系统已启动风控系统，请下月再来申请。</p>
                    </div>
                </div>
                <div class="dialog-footer">
                    <a href="javascript:void(0)" class="reset" @click="dialogHide">取消</a>
                    <a href="javascript:void(0)" class="confirm" @click="dialogHide">确定</a>
                </div>
            </div>
        </div>
        <div class="bot-link">
            <a href="javascript:void(0)" :class="{'disabled':selectedInformationid === null}" @click="aftermarketCommit(selectedInformationid)">下一步</a>
        </div>
    </div>
</template>

<script>
import { getInformationList, checkAftermarket } from '@api/aftermarket'
export default {
    name: 'AftermarketInformation',
    components: {
    },
    data() {
        return {
            dialogVisible: false,
            totalCount: 0, // 总条目数
            currentPage: 1,
            pageSize: 20, // 每页4条
            informationList: [],
            selectedInformationid: null
        }
    },
    created() {
        this.getInformationList()
    },
    methods: {
        dialogHide() {
            this.dialogVisible = false
        },
        aftermarketCommit(informationid) {
            console.log('跳转页面', informationid)
            // router.push({ name: 'user', params: { userId: 123 } })
            // router.push({ path: 'register', query: { plan: 'private' }})
            // 检测售后次数 status 1次数正常 2次数异常
            if (!informationid) return
            this.checkAftermarket().then(status => {
                //  console.log(status)
                if (status === 1) {
                    this.$router.push({ path: '/my/aftermarket-commit', query: { informationid }})
                } else {
                    // console.log('您申请售后次数异常，系统已启动风控系统，请下月再来申请。')
                    this.dialogVisible = true
                }
            })
            // this.$router.push({ path: '/my/aftermarket-commit', query: { informationid }})
            // this.dialogVisible = true
        },
        aftermarketSelected(id) {
            console.log(id)
            // console.log(this.$refs.aftermarketLi)
            this.informationList.forEach((item) => {
                // console.log(0)
                if (item.informationid === id) {
                    if (this.selectedInformationid !== id) {
                        // console.log(1)
                        item.selected = true
                    } else {
                        item.selected = false
                    }
                } else {
                    item.selected = false
                }
            })
            this.selectedInformationid = this.selectedInformationid === id ? null : id
        },
        async checkAftermarket() {
            const result = await checkAftermarket()
            return new Promise((resolve, reject) => {
                if (result.code === 1001) {
                    resolve(result.detail.status)
                } else {
                    reject('请求出错，请重新发起申请')
                }
            })
        },
        async getInformationList() {
            const res = await getInformationList({ page: this.currentPage })
            // console.log('informationList res', res.list)
            if (res.code === 1001) {
                res.list.list.forEach(item => {
                    item['selected'] = false
                })
                this.informationList = res.list.list
                console.log(this.informationList)
                this.pageSize = res.list.pagesize
                this.totalCount = res.list.count
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.dialog-box{
    position: fixed;left: 0;top: 0;right: 0;bottom: 0;z-index: 9999;background: rgba(0,0,0,0.6);
    display: none;
    .inner{
        width: 80%; border-radius: 6px;
        background: #fff;
        position: absolute;left: 50%;top: 50%;
        transform: translate(-50%,-50%)
    }
    .dialog-header{
        position: relative;padding: 15px 20px;font-size: 14px;color: #333;
        border-bottom: 1px #f1f1f1 solid;
    }
    .dialog-cont{
        padding:15px 20px;
    }
    .dialog-footer{
        padding:0 20px;border-top: 1px #f1f1f1 solid;
        a{
            width: 50%;box-sizing: border-box;text-align: center;
            line-height: 44px;display: inline-block;padding: 0 20px;font-size: 14px;
            &.reset{
                color: #787878;
            }
        }
    }
    &.show{
        display: block;
    }
}
.tips-box{
    color: #999;padding:0px 0;
    h4{
        color: #666;font-size: 14px;padding-bottom: 10px;
    }
}
.select-list{
    padding-top: 20px;
    h3{
        text-align: center;font-size: 14px;color: #787878;font-weight: normal;
    }
}
.info-list{
    padding: 20px 10px;
    li{
        width: 50%;display: inline-block;vertical-align: top;
        box-sizing: border-box;padding:8px;
        a{
            color: #999;display: block;border:3px transparent solid;position: relative;
            .thumb{
                width: 100%;overflow: hidden;
                img{
                    display: block;
                    width: 100%;
                    height: 180px;
                    -o-object-fit: cover;
                    object-fit: cover;
                }
            }
            .info-name{
                font-size: 13px;color: #202020;
                text-overflow: ellipsis;white-space: nowrap;overflow: hidden;
                padding: 10px;
                background: #fff;
            }
            .info-price{
                background: #fff;padding: 0 10px 10px 10px;
            }
            .info-price-item{
                display: block;padding-bottom: 5px;
            }
            &:after{
                content: '';
                width: 30px;height: 30px;
                background: url(~@a/img/icon-correct.png);
                background-size: cover;
                position: absolute;left: 0;top: 0;
            }
        }
        &.selected{
            a{
                border:3px #009933 solid;
                &:after{
                    background: url(~@a/img/icon-correct-green.png);
                    background-size: cover;
                }
            }
        }
    }
}
.bot-link{
    position: fixed;left: 0;right: 0;bottom: 0;z-index: 99;
    background: #fff;
    padding: 12px 20px;
    a{
        background: #ff0000;font-size: 16px;line-height: 40px; color: #fff;display: block;text-align: center;
        border-radius: 6px;
        &.disabled{
            color: #999;background: #ccc;cursor: no-drop
        }
    }
}

@media screen and (min-width:411px) {

}

</style>
