<template>
    <div class="main">
        <!-- <simple-header :userInfo="userInfo" /> -->
        <!-- <page-header :userInfo="userInfo" :hasMore="false" title="我的消息" /> -->
        <div class="msg-wrapper">
            <section v-for="item in msgList" :key="item.news_id" class="msg-item" :class="{ 'msg-important': item.isread==='0' }">
                <h3 class="msg-item-tit">
                    <!-- <i v-if="item.isread==='0'" class="msg-item-icon" /> -->
                    <span class="msg-item-txt">违规信息</span>
                </h3>
                <div class="msg-item-toolbar">{{ item.addtime }}</div>
                <p class="msg-content">{{ item.content }}</p>
                <i v-if="item.isread==='0'" class="msg-item-icon iconfont icon-impotant-face"></i>
            </section>
            <section v-if="!msgList[0]" class="nodata">
                <p class="content">暂无数据</p>
            </section>
        </div>
        <!-- <simple-footer /> -->
    </div>
</template>
<script>
// import simpleHeader from '@c/simpleHeader.vue'
// import simpleFooter from '@c/simpleFooter.vue'
// import breadcrumbs from '@c/breadcrumbs'
import pageHeader from '@c/pageHeader'
import mixin from '@c/mixins'
import authMixin from '@c/mixins/auth'
import { newsList } from '@api/my'
// import '../mock'
export default {
    name: 'MyMessage',
    mixins: [ mixin, authMixin ],
    // props: {
    //     userInfo: {
    //         type: Object
    //     }
    // },
    components: {
        pageHeader,
        // simpleHeader,
        // simpleFooter,
        // breadcrumbs
    },
    data() {
        return {
            msgList: []
        }
    },
    created() {
        this.newsList()
        this.getAccount()
    },
    methods: {
        // 获取消息列表
        async newsList() {
            const res = await newsList()
            console.log('newsList res', res)
            if (res.code === 1001) {
                this.msgList = res.list.list
                // this.msgList = [
                //     { news_id: '1', addtime: '2016-05-06', isread: '1', content: '第一个消息' },
                //     { news_id: '2', addtime: '2016-05-06', isread: '0', content: '第二个消息' },
                // ]
            }
        }
    }
}
</script>
<style lang="scss" scoped>
// .main.aftermarket {
//     position: absolute;
//     width: 100%;
//     height: 100%;
// }
.msg-wrapper {
    height: calc(100% - 135px);
}
.msg-title {
    margin-bottom: 30px;
    height: 60px;
    line-height: 60px;
    text-align: center;
    font-size: 18px;
    border-top: 1px solid $grayest;
    background-color: $white;
}
.msg-item-tit {
    margin-bottom: 10px;
    font-weight: normal;
    font-size: $fs18;
    color: $black;
    > * {
        @include inlineBlock();
    }
}
.msg-item-icon {
    position: absolute;
    top: 15px;
    left: 10px;
    width: 10px;
    height: 10px;
    color: $yellow;
    font-size: $fs36;
    // background-color: $red;
    // border-radius: 50%;
}
.msg-item-toolbar {
    position: absolute;
    top: 22px;
    right: 20px;
}
.msg-item {
    position: relative;
    margin: 10px auto;
    padding: 15px 20px;
    // width: 1200px;
    color: $light;
    background-color: $white;
    &.msg-important {
        padding-left: 60px;
        .msg-item-tit {
            font-weight: bold;
        }
    }
}
.nodata {
    position: relative;
    > .content {
        padding: 100px;
        text-align: center;
        color: $light;
    }
}
</style>
