<template>
    <div class="my-accout">
        <!-- <page-header :userInfo="userInfo" :hasMore="false" title="我的K点" /> -->
        <div class="my-body">
            <h3 class="k-title">可用K点<span class="k-num">{{ kNum }}</span></h3>
            <section v-for="(item, index) in kList" :key="index" class="k-item">
                <h4 class="k-content">
                    {{ [ '客服操作K点', '订单使用抵扣k点', '订单退款K点' ][ +item.type - 1 ] }}
                    <span v-if="['2', '3'].includes(item.type)">订单{{ item.orderid }}，{{['使用', '退单入账'][+item.type - 2]}}K点</span>
                    <span class="k-time">{{ item.addtime }}</span>
                </h4>
                <p class="k-dintegral">{{ item.dintegral }}</p>
            </section>
        </div>
    </div>
</template>
<script>
import pageHeader from '@c/pageHeader'
import { integralList } from '@api/my'
// import '../mock'
export default {
    name: 'MyKPoint',
    props: {
        userInfo: {
            type: Object
        }
    },
    data() {
        return {
            kNum: '0',
            kList: [],
            memo: [ '客服操作K点', '订单使用抵扣k点', '订单退款K点' ]
        }
    },
    components: {
        pageHeader,
    },
    created() {
        // 获取K点列表
        const self = this
        this.integralList()
        // 监听pageHeader转发的登录信息判断用户是否已登录
        // this.$bus.$on('userInfo', infoObj => {
        //     // console.log('infoObj', infoObj)
        //     if (infoObj && infoObj.integral) {
        //         this.kNum = infoObj.integral
        //     }
        // })
        // 第二次要从缓存取
        // if(Object.keys(this.userInfo).length === 0) {
        // const userInfoCache = sessionStorage.getItem('USER_INFO')
        // console.log('userInfoCache', userInfoCache)
        // if (userInfoCache) {
        //     try {
        //         self.kNum = JSON.parse(userInfoCache).integral
        //     } catch (err) {
        //         console.log('err', err)
        //     }
        // }
        // }
    },
    methods: {
        async integralList() {
            const res = await integralList()
            console.log('integralList res', res)
            const list = res.list
            if (res.code === 1001) {
                this.kList = res.list.list
                // this.kList = [
                //     { orderid: '123456789', type: '2', addtime: '2021-05-06 12:12:12', now_integral: '5.00', dintegral: '6.00' },
                //     { orderid: '123456789', type: '2', addtime: '2021-05-06 12:12:12', now_integral: '5.00', dintegral: '6.00' },
                // ]
                this.kNum = this.kList[0] ? this.kList[0].now_integral : 0
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.my-title {
    margin-bottom: 20px;
    font-weight: normal;
    font-size: 18px;
}
.my-body {
    // min-height: 1000px;
    position: relative;
    // padding: 20px;
    // background-color: $white;
}
.k-item {
    position: relative;
    display: flex;
    justify-content: space-between;
    padding: 20px;
    font-size: $fs18;
    background-color: $white;
    color: $redest;
    &::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 20px;
        height: 1px;
        background-color: $lightest;
        right: 20px;
    }
    &:last-child {
        &::after {
            display: none;
        }
    }
}
.k-content {
    font-weight: normal;
}
.k-title {
    margin-bottom: 10px;
    padding: 10px 10px 20px;
    font-size: $fs16;
    text-align: center;
    background-color: $white;
}
.k-num {
    padding: 0 10px;
    font-size: $fs36;
    color: $red;
}
.k-dintegral {
    padding: 10px 0;
    font-size: $fs30;
    color: $black;
}
.k-time {
    padding: 5px 0;
    display: block;
    font-size: $fs14;
    color: $light;
}
</style>
