// 常规列表混入
import { isGetOpenid, getUserOpenid } from '@api'
import Utils from '@a/js/utils'
export default {
    data() {
        return {
            reg: {
                mailbox: /^\w+@\w+\.(cn|com)$/, // 邮箱, /数字字母下划线@数字字母下划线.cn|com/
                mailboxPrompt: '请输入正确的邮箱格式',
                tel: /^[1][3,4,5,7,8][0-9]{9}$/, // 手机
                telPrompt: '请输入正确的手机号格式',
                doubleReg: /^\d+(\.\d+)?$/, // 小数验证
                doubleRegPrompt: '只允许输入数字(包括小数)',
                fax: /^(\d{3,4}-)?\d{7,8}$/, // 传真校验
                faxPrompt: '请输入正确的传真格式',
                noCn: /^[^\u4e00-\u9fa5]+$/, // 不允许中文
                noCnPrompt: '不允许输入中文',
                numLength: /^[A-Za-z_0-9-]{1,50}$/, // 只允许输入英文(半角), 数字, 下划线, 横线, 且长度不超过50
                lengthPrompt: '只允许输入英文(半角)，数字，下划线, 横线且字符长度不允许超过50',
                positiveInteger: /^[1-9]\d*$/, // 只允许输入正整数（不包括0)
                positivePrompt: '只允许输入大于0的正整数',
                en: /^[A-Za-z]+$/, // 只允许英文
                enPrompt: '只允许输入英文(半角)',
                enUpperNumberline: /^[A-Z_0-9]+$/,
                enUpperNumberlinePrompt: '只允许输入大写英文(半角)，数字，下划线',
                enCnNumber: /^[a-zA-Z0-9\u4e00-\u9fa5]+$/,
                enCnNumberPrompt: '只允许输入中文，英文(半角)，数字',
                noSpace: /^[\s\S]*.*[^\s][\s\S]*$/ // 非空校验
            },
            countDownTimeMax: 60, // 倒计时60秒
            loading: false // 加载页面
            // testData1: '',
            // testData2: ''
        }
    },
    methods: {
        // 重置统一
        resetForm() {
            for (const key in this.form) {
                this.form[key] = ''
            }
        },
        // 限制输入数字
        numberInputChange(value) {
            this[value] = this[value].replace(/[^\d]/g, '')
        },
        // 倒计时
        countDown(key = 'COUNTDOWN_LOGIN') {
            this.timer = setInterval(_ => {
                // console.log('this.countDownTime', this.countDownTime)
                if (this.countDownTime > 0 && this.countDownTime <= 60) {
                    this.countDownDisabled = true
                    this.countDownTime--
                } else {
                    this.countDownDisabled = false
                    this.countDownTime = 0
                    // 清除定时器
                    if (this.timer) window.clearInterval(this.timer)
                    // 清除缓存值
                    sessionStorage.clear(key)
                }
                console.log('this.countDownTime countDown', this.countDownTime)
                // 记录当前时间更新缓存
                // sessionStorage.setItem(key, new Date().getTime())
            }, 1000)
        },
        // 回跳：redirect_uri/?code=CODE&state=STATE
        async handleOpenid() {
        }
    },
    async beforeCreate() {
        // console.log('getBrowser', Utils.getBrowser())
        // alert(window.navigator.userAgent)
        // if (Utils.getBrowser() === 'mobile') { //判断是否是移动设备打开
        // var ua = navigator.userAgent.toLowerCase(); //获取判断用的对象
        // if (typeof WeixinJSBridge == "undefined") {
        if (window.navigator.userAgent.includes('MicroMessenger')) {
            // 在微信中打开
            // alert('微信浏览器打开！')
            if (!sessionStorage.getItem('appid')) {
                const res = await isGetOpenid()
                console.log('res', res)
                const resDetail = res.detail
                if (res.code === 1001 && resDetail && resDetail.status === 2) {
                    // const redirectUri = encodeURIComponent('http://m.kamisoul.net/#/')
                    const redirectUri = encodeURIComponent(resDetail.url)
                    // const redirectUri = encodeURIComponent(location.href)
                    const appid = resDetail.appid
                    // alert(`redirectUri：${redirectUri}`)
                    // alert(`appid：${appid}`)
                    // alert(`appid=${appid}&redirect_uri=${redirectUri}`)
                    if (appid) {
                        sessionStorage.setItem('appid', appid)
                        sessionStorage.setItem('url', `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${redirectUri}&connect_redirect=1&response_type=code&scope=snsapi_base&state=STATE`)
                    }
                    location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${redirectUri}&connect_redirect=1&response_type=code&scope=snsapi_base&state=STATE`
                }
            }
        } else {
            console.log('非微信浏览器打开！')
            // alert('非微信浏览器打开！')
        }
        // }
    },
    beforeRouteEnter(to, from, next) {
        // alert(`location.href: ${location.href}`)
        // console.log('to, from', to, from)
        // alert(`from：${from.path}`)
        // alert(`to：${to.path}`)
        // let url = location.href
        next(async(vm) => {
            // vm.testData1 = sessionStorage.getItem('url')
            // vm.testData2 = url
            const code = vm.$utils.getQueryString('code')
            // alert(`code：${code}`)
            if (code) {
                const res = await getUserOpenid({ code })
                // alert(`getUserOpenid返回：${JSON.stringify(res)}`)
            }
            // console.log('from.query',  from.query)
            // console.log('to.query', to.query)
        })
    }
}
