<template>
    <div class="layout layout-prod">
        <section class="layout-head">
            <h3 class="layout-title">最新产品</h3>
        </section>
        <section class="layout-prod-body">
            <router-link v-for="item in list" :key="item.commodityid" class="info-link" :to="`/product/detail?commodityid=${item.commodityid}`">
                <img class="info-img" :src="item.img" :alt="item.title">
                <span class="info-name text-ellipsis">{{ item.title }}</span>
                <!-- <span class="product-mask">
                    <i class="product-mask-icon">限购</i>
                    <i class="product-mask-txt">24小时55分钟46秒 截止，限购&nbsp;1&nbsp;个</i>
                </span> -->
                <!-- <span v-if="item.isseal===1" class="product-none">商品暂时无货</span> -->
                <!-- <span class="product-none">商品暂时无货</span> -->
                <p class="product-price">
                    <!-- <i>￥</i>{{ item.money1 === '0.00' ? item.money2 : item.money1 === item.money2 ? item.money1 : `${item.money1}-${item.money2}` }} -->
                    <!-- <i>￥</i><span class="product-price-num">{{1288.00}}</span> -->
                </p>
                <p class="info-price">
                    <span class="info-price-item">出荷时间：{{ $utils.getPublishString(item.publishtype, item.publishyear, item.publishtime) }}</span>
                    <i class="iconfont icon-arrow-round-r"></i>
                </p>
            </router-link>
        </section>
    </div>
</template>
<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
export default {
    name: 'LayoutProd',
    components: {
        Swiper,
        SwiperSlide
    },
    props: {
        list: {
            type: Array,
            required: true,
            default: null
        }
    },
    data(vm) {
        return {
            swiperOptions: {
                slidesPerView: 'auto',
                // spaceBetween: 20,
                // centeredSlides: true,
                scrollbar: {
                    el: '.swiper-scrollbar'
                },
                // autoplay: true, // 等同于以下设置
                loop: vm.list.length > 4,
                autoplay: {
                    delay: 20000,
                    stopOnLastSlide: false,
                    disableOnInteraction: false
                }
                // breakpoints: {
                //     1200: {
                //         slidesPerView: 2,
                //     },
                // }
                // Some Swiper option/callback...
            }
        }
    },
    computed: {
        swiper() {
            return this.$refs.mySwiper.$swiper
        },
        swiperList() {
            // 先分组(0-3, 4-7, 8-11, 12-15, 16-19)
            const arr = []
            const groupNum = Math.ceil(this.list.length / 4)
            console.log(groupNum)
            for (let i = 0; i < groupNum; i++) {
                arr.push(this.list.slice(4 * i, 4 * i + 4))
            }
            console.log(arr)
            return arr
        }
    }
}
</script>
<style lang="scss" scoped>
    .layout.layout-prod {
        width: auto;
        margin-bottom: 0;
        // background: $red;
        .layout-head {
            margin-bottom: 5px;
            border-bottom: none;
        }
    }
    
    .info-link {
        position: relative;
        display: inline-block;
        vertical-align: top;
        width: calc(50% - 5px);
        margin: 0 10px 10px 0;
        border-radius: 6px;
        background-color: $white;
        border: 1px solid $lighter;
        &:nth-child(2n) {
            margin-right: 0;
        }
    }
    .info-img {
        display: block;
        width: 100%;
        height: 180px;
        border-radius: 6px 6px 0 0;
        // @include widthHeight(285px, 285px);
        object-fit: cover;
    }
    .info-name {
        padding: 5px 5px 0;
        display: block;
        height: 20px;
        font-size: $fs12;
        color: $black;
    }
    .info-price {
        // border-bottom: 1px solid $gray;
        padding: 0 5px 5px;
        display: flex;
        justify-content: space-between;
        .iconfont {
            color: $red;
        }
    }
    .info-price-item {
        display: block;
        color: $light;
    }
    .layout-prod-body {
        margin: 0 10px;
    }
    .product-price {
        padding: 3px 0;
        color: $red;
    }
    .product-price-num {
        font-size: $fs20;
    }
    .product-none {
        position: absolute;
        top: 154px;
        left: 0;
        width: 100%;
        height: 26px;
        line-height: 26px;
        text-align: center;
        color: $white;
        background: rgba($color: #000000, $alpha: .5);
    }
</style>
