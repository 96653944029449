<template>
    <div class="main pay">
        <!-- <simple-header :userInfo="userInfo" /> -->
        <!-- <pay-logo /> -->
        <!-- <page-header :userInfo="userInfo" title="订单提交成功" :hasMore="false" /> -->
        <section class="pay-item">
            <!-- <h3 class="pay-title">订单提交成功</h3> -->
            <p class="pay-txt">您的订单提交成功，请尽快付款！订单号：{{ orderId }}</p>
        </section>
        <div class="order-info">
            <div class="oi-header">
                <h3 class="oi-tit">待支付金额</h3>
            </div>
            <div class="oi-body">
                <p class="pay-price"><strong>{{ money }}</strong><i>元</i></p>
                <p v-if="showIntegral" class="pay-txt">
                    <!-- <el-checkbox v-model="payKC">使用K点抵扣：{{ isuseIntergal }}</el-checkbox> -->
                    <span class="switch-wrapper">
                        <mt-switch v-model="payKC" />
                    </span>
                    使用K点抵扣：{{ isuseIntergal }}
                </p>
            </div>
        </div>
        <section class="order-info">
            <div class="oi-header">
                <h3 class="oi-tit">选择支付方式</h3>
            </div>
            <div class="oi-body">
                <p class="pay-choise">
                    <!-- 2:支付宝；1:微信支付 -->
                    <a href="javascript:;" class="pay-choise-item" :class="{ active: payMethod===2}" @click="payMethod=2"><i class="icon icon-zhifubao" /><span class="pay-choise-txt">支付宝支付</span></a>
                    <a href="javascript:;" class="pay-choise-item" :class="{ active: payMethod===1 }" @click="payMethod=1"><i class="icon icon-wxzhifu" /><span class="pay-choise-txt">微信支付</span></a>
                </p>
            </div>
        </section>
        <div ref="qrcodeform" v-show="payMethod===2"></div>
        <div class="btn-wrapper">
            <!-- 2:支付宝；1:微信支付 -->
            <a v-if="payMethod===1" class="btn-primary btn-round" @click.prevent="checkOrderPay(1)">立即支付</a>
            <a v-if="payMethod===2" class="btn-primary btn-round" @click="checkOrderPay(2)">立即支付</a>
            <!-- 支付宝先不检查是否支付 -->
            <!-- <a v-if="payMethod===2" :href="`http://kamisoul.renmnet.com/order/orderPays?type=${payMethod}&orderid=${orderId}&money=${money}`" class="btn-primary btn-round">立即支付</a> -->
        </div>
        <!-- <simple-footer /> -->
        <div v-if="showMask" class="payment-mask">
            <div class="payment-tips">
                <ul class="payment-tips-list">
                    <li>请确认微信支付是否已完成</li>
                    <li @click="checkPay">已完成支付</li>
                    <li @click="$router.push('/my/order/1')">支付遇到问题，重新支付</li>
                </ul>
            </div>
        </div>
    </div>
</template>
<script>
// import simpleHeader from '@c/simpleHeader.vue'
// import simpleFooter from '@c/simpleFooter.vue'
import mixin from '@c/mixins'
import authMixin from '@c/mixins/auth'
import { getOrderPayStatus, checkOrderStatus, checkOrderEndStatus, orderPays, orderPaye, getUserIntegral, checkOrderType } from '@api/order'
export default {
    name: 'Payment',
    components: {
    },
    mixins: [mixin, authMixin],
    data() {
        return {
            payKC: false,
            payMethod: 1, // 1:微信支付；2:支付宝
            orderId: '',
            money: '',
            isuseIntergal: 0,
            showIntegral: true,
            orderstatus: '', // 如果为3，需要追加尾款，调用另一个付尾款接口orderPaye
            showMask: false,
            payorderid: ''
        }
    },
    created() {
        this.getAccount()
    },
    methods: {
        async getUserIntegral() {
            const res = await getUserIntegral()
            if (res && res.detail) {
                // 缓存登录信息
                // this.userInfoObj = Object.assign({}, res.detail)
                // console.log('userInfoObj1', this.userInfoObj)
                this.isuseIntergal = res.detail.integral
            }
        },
        async getParamsData() {
            console.log('query data', this.$route.query)
            if (this.$route.query) {
                this.orderId = this.$route.query['orderid']
                // this.money = this.$route.query['money']
                this.orderstatus = Number(this.$route.query['orderstatus'])
            }
            // 检查订单类型
            const res = await checkOrderType({ orderid: JSON.parse(this.orderId)[0] })
            const resInfo = res.detail.status
            if (resInfo.status === 1) {
                this.money = resInfo.paymoney
                this.showIntegral = resInfo.isintegral === 0
                this.getUserIntegral()
            }
            // try {
            //     this.isuseIntergal = JSON.parse(sessionStorage.getItem('USER_INFO')).integral
            // } catch (err) {
            //     console.log(err)
            // }
        },
        // 检查订单是否已经支付type:2,支付宝;1.微信; isuse_intergal(0不使用，1使用)
        async checkOrderPay(type) {
            const params = {
                orderid: this.orderId,
                money: this.money,
                type: this.payMethod,
                // isuse_intergal: this.isuseIntergal
                isuse_intergal: this.payKC ? 1 : 0 // 0不使用，1使用
            }
            // checkOrderEndStatus为付尾款检查: 1 可以正常支付  2001 一分钟内连续产生交易单最多5条  2002 一小时内最多10条   2 已付款 3订单异常
            const res = this.orderstatus === 3 ? await checkOrderEndStatus(params) : await checkOrderStatus(params)
            console.log('checkOrderStatus res', res)
            const resDetail = res.detail
            if (resDetail) {
                if (resDetail.status === 1) { // 可以正常支付
                    this.orderPays(type)
                    // if(type === 1) { // 微信
                    //     this.$router.push({ path: 'payment-method', query: { type: this.payMethod, orderid: this.orderId, orderstatus: this.orderstatus, money: this.money, paykc: this.payKC } })
                    // } else if(type === 2) { // 支付宝
                    //     // this.orderstatus === 3 为尾款支付，需调用orderPaye接口
                    //     const orderPayMethod = this.orderstatus === 3 ? 'orderPaye' : 'orderPays'
                    //     location.href = `http://kamisoul.renmnet.com/order/${orderPayMethod}?type=${this.payMethod}&orderid=${this.orderId}&money=${this.money}&isuse_intergal=${this.payKC ? 1 : 0}`
                    // }
                } else if (resDetail.status === 2) { // 已支付过
                    this.$router.push({ path: '/payment-result', query: { type: 0, payorderid: resDetail.payorderid }})
                } else if (resDetail.status === 2001) { // 一分钟内连续产生交易订单最多5条
                    this.$toast('一分钟内连续产生交易订单最多5条')
                } else if (resDetail.status === 2002) { // 一小时内连续产生交易订单最多10条
                    this.$toast('一小时内连续产生交易订单最多10条')
                } else if (resDetail.status === 3) { // 订单异常
                    this.$toast('订单异常')
                }
            }
        },
        // orderid （类似 ["20200610100991028", "20200610100100480"]）, money（总金额），type（1微信2支付宝），isuse_intergal（1 使用用户积分 0不使用）
        async orderPays(type) {
            const params = {
                orderid: this.orderId,
                money: this.money,
                type: this.payMethod,
                // isuse_intergal: this.isuseIntergal
                isuse_intergal: this.payKC ? 1 : 0 // 0不使用，1使用
            }
            // this.orderstatus === 3 为尾款支付，需调用orderPaye接口
            const res = this.orderstatus === 3 ? await orderPaye(params) : await orderPays(params)
            console.log('orderPays res', res)
            // alert(JSON.stringify(res))
            if (res) {

                //const resDetail = res.detail
                
		//alert(this.payorderid)
		//alert('微信浏览器打开！')
                // 在微信中打开
                if (window.navigator.userAgent.includes('MicroMessenger')) {
		    this.payorderid = res.payorderid
                    //alert('微信浏览器打开！')
                    this.showMask = true
                    function onBridgeReady() {
                        WeixinJSBridge.invoke ('getBrandWCPayRequest', {
                                "appId": res.appId,     //公众号ID，由商户传入     
                                "timeStamp": res.timeStamp,         //时间戳，自1970年以来的秒数     
                                "nonceStr": res.nonceStr, //随机串     
                                "package": res.package,     
                                "signType": res.signType, // 微信签名方式：     
                                "paySign": res.paySign // 微信签名 
                            },
                            function(res) {
                                if (res.err_msg == "get_brand_wcpay_request:ok" ) {
                                    // 使用以上方式判断前端返回,微信团队郑重提示：
                                    // res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
                                    this.$toast('支付成功')
                                } 
                            })
                    }
                    if (typeof WeixinJSBridge === 'undefined') {
                        if (document.addEventListener) {
                            document.addEventListener('WeixinJSBridgeReady', onBridgeReady, false)
                        } else if (document.attachEvent) {
                            document.attachEvent('WeixinJSBridgeReady', onBridgeReady)
                            document.attachEvent('onWeixinJSBridgeReady', onBridgeReady)
                        }
                    } else {
                        onBridgeReady()
                    }
                } else {
                    const resDetail = res.detail
                    if (resDetail) {
                         this.payorderid = resDetail.payorderid
                        if (resDetail && resDetail.status === 1 && resDetail.code_url) {
                            // this.getQrCode(resDetail.code_url)
                            // 倒计时
                            // this.countDown()
                            // this.getOrderPayStatus(resDetail.payorderid)
                            console.log('type', type)
                            if (type === 1) { // 微信
                                console.log('非微信浏览器打开！')
                                // this.$router.push({ path: 'payment-method', query: { type: this.payMethod, orderid: this.orderId, money: this.money - this.isuseIntergal, codeurl: resDetail.code_url, payorderid: resDetail.payorderid } })
                                let aLink = document.createElement('a')
                                aLink.style.display = 'none'
                                aLink.href = resDetail.code_url
                                // aLink.target = '_bank';
                                document.body.appendChild(aLink)
                                aLink.click()
                                document.body.removeChild(aLink) // 下载完成移除元素
                                // location.href = resDetail.code_url
                                this.showMask = true
                                // }
                            }
                        } else if (resDetail.status === 99) {
                            // this.$notify({ title: '提示', type: 'error', message: '系统繁忙，请稍后再试', position: 'bottom-right' })
                            this.$toast('系统繁忙，请稍后再试')
                        } else if (resDetail.status === 1001) {
                            this.$router.push({ path: '/payment-result', query: { type: 0, payorderid: resDetail.payorderid }})
                            // this.$notify({ title: '提示', type: 'success', message: '积分抵扣成功', position: 'bottom-right' })
                            this.$toast('积分抵扣成功')
                        } else {
                            // this.$notify({ title: '提示', type: 'error', message: '有异常订单不能支付', position: 'bottom-right' })
                            this.$toast('有异常订单不能支付')
                        }
                    } else { // 支付宝支付时直接返回form字符串没有detail
                        if (type === 2) { // 支付宝
                            // this.orderstatus === 3 为尾款支付，需调用orderPaye接口
                            // const orderPayMethod = this.orderstatus === 3 ? 'orderPaye' : 'orderPays'
                            // location.href = `http://kamisoul.renmnet.com/order/${orderPayMethod}?type=${this.payMethod}&orderid=${this.orderId}&money=${this.money}&isuse_intergal=${this.payKC ? 1 : 0}`
                            this.$refs.qrcodeform.innerHTML = res
                            const payForm = this.$refs.qrcodeform.querySelector('form')
                            payForm.submit()
                        }
                    }
                }
            }
        },
        async checkPay() {
            const res = await getOrderPayStatus({ payorderid: this.payorderid })
            const resDetail = res.detail
            console.log(this.payorderid)
            if (resDetail && resDetail.status === 1) {
                // window.clearInterval(this.timer)
                this.$router.push({ path: '/payment-result', query: { type: 0, payorderid: this.payorderid }})
            }
        }
    },
    beforeRouteEnter(to, from, next) {
        console.log('from, to', from, to)
        if (from.query) {
            next(vm => {
                vm.getParamsData()
            })
        }
    }

}
</script>
<style lang="scss" scoped>
.pay-logo {
    position: relative;
    height: auto;
}
.pay-item {
    position: relative;
    // width: 1200px;
    min-height: 30px;
    padding: 20px;
    margin: 0 auto 10px;
    background-color: $white;
    &.mt20 {
        margin-top: 20px;
    }
}
.pay-title {
    margin-bottom: 10px;
    font-size: 18px;
}
.pay-txt {
    color: $light;
}
.pay-price {
    margin-bottom: 10px;
    font-size: 20px;
    color: $red;
    > i {
        font-size: 14px;
        color: $light;
    }
}
.pay-txt-strong {
    margin-bottom: 10px;
    font-size: 14px;
}
.pay-choise {
    display: flex;
}
.pay-choise-item {
    @include inlineBlock();
    // width: 200px;
    padding: 20px 30px;
    margin-right: 10px;
    border: 1px solid $gray;
    font-size: 18px;
    > .icon {
        margin-right: 10px;
    }
    &.active {
        padding: 18px 28px;
        border: 3px solid $red;
    }
}
.pay-choise-txt {
    vertical-align: -2px;
    font-size: $fs14;
}
.btn-wrapper {
    // width: 1200px;
    padding: 0 20px;
    margin: 10px auto 20px;
    .btn-primary {
        // width: 200px;
        cursor: pointer;
    }
}
.switch-wrapper {
    display: inline-block;
    vertical-align: middle;
}
.payment-mask {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    z-index: 9;
    top: 0;
    left: 0;
    width: 100%;
    bottom: 0;
    background-color: rgba($color: $black, $alpha: 0.5);
}
.payment-tips {
    width: 80%;
    background-color: $white;
    border-radius: 6px;
}
.payment-tips-list {
    border-radius: 6px;
    li {
        padding: 12px 20px;
        border-bottom: 1px solid $lightest;
        text-align: center;
        &:nth-child(2) {
            color: $red;
            &:focus,
            &:active {
                background-color: $lighter;
            }
        }
        &:last-child {
            border-bottom: 0;
            &:focus,
            &:active {
                background-color: $lighter;
            }
        }
    }
}
</style>
