<template>
    <div class="my-address">
        <!-- <div class="my-head">
            <h3 class="my-title">地址管理</h3>
        </div> -->
        <!-- <page-header :userInfo="userInfo" :hasMore="false" title="选择收货地址" /> -->
        <div class="my-body">
            <div v-for="item in addressList" :key="item.user_address_id" class="address-item">
                <section class="address-item-body">
                    <h3 class="address-item-block address-name">
                        <span class="address-name-txt">{{ item.name + ' ' + item.mobile }}</span>
                    </h3>
                    <p class="address-item-block address-item-border">
                        <span v-if="item.isdefault==='1'" class="address-tag">默认</span>
                        <span class="address-detail">{{ item.province + item.city + (item.area || '') + item.address }}</span>
                    </p>
                    <section class="address-toolbar">
                        <!-- <a href="javascript:;" class="btn-primary btn-small btn-rounder" @click="showAddressPage(item)">编辑</a> -->
                        <i class="iconfont icon-edit-o" @click="showAddressPage(item)" title="编辑"></i>
                    </section>
                </section>
                <p class="address-item-footer">
                    <a class="form-check" :class="{ active: item.isdefault==='1' }" href="javascript:;" @click="setdefault(item.user_address_id)">
                        <i class="form-check-icon"><i class="iconfont icon-gou"></i></i>
                        <span class="form-check-label">设为默认</span>
                    </a>
                    <!-- <mt-checklist :max="item.isdefault!=='1'?1:0" v-model="item.defaultAddr" :options="['设为默认']"></mt-checklist> -->
                    <a class="address-link" href="javascript:;" @click="deladdress(item.user_address_id)">删除</a>
                </p>
            </div>
            <div v-if="addressList.length===0" class="address-none">
                <i class="iconfont icon-location"></i>
                <h3 class="address-none-tit">暂无收货地址</h3>
                <p class="address-none-tips">您还没有收货地址哦~</p>
            </div>
            <div class="btn-wrapper">
                <a href="javascript:;" class="btn-primary address-add" @click="showAddressPage(null)">新增收货地址</a>
                <!-- <small class="address-add-tips">最多可以创建20个收货地址</small> -->
            </div>
        </div>
        <!-- <mt-popup
            class="popup-content"
            v-model="showAddrPage"
            position="bottom"> -->
        <page-address v-show="showAddrPage" v-model="current" @getData="getData" @close="showAddrPage=false" />
        <!-- </mt-popup> -->
    </div>
</template>
<script>
import { getAddressList, setdefault, deladdress } from '@api/my'
import pageHeader from '@c/pageHeader'
import pageAddress from './page-address'
export default {
    name: 'MyOrder',
    props: {
        userInfo: {
            type: Object
        }
    },
    components: {
        pageHeader,
        pageAddress
    },
    data() {
        return {
            activeName: '1',
            addressList: [
                // { addressid: '1', name: '大胖鸡', tel: '159****8085', address: '上海普陀区城区宁夏路627号', default: true },
                // { addressid: '2', name: '大胖鸡', tel: '159****8085', address: '上海普陀区城区宁夏路627号', default: false }
            ],
            type: 'add',
            current: null,
            showAddrPage: false,
            provinceList: [],
            defaultAddr: ''
        }
    },
    created() {
        this.getData()
        // this.getProvince()
    },
    methods: {
        async getData() {
            const res = await getAddressList()
            this.addressList = res.detail
            // this.addressList = []
            // 如果地址列表只有一条数据且没有设为默认，则自动设置为默认地址
            let addressList = this.addressList
            console.log('addressList.length', addressList.length)
            if (addressList.length > 0) {
                addressList.forEach(item => {
                    item.defaultAddr = ''
                })
                const defaultAddress = addressList.find(item => item.isdefault === '1')
                if (addressList.length === 1 && !defaultAddress) {
                    this.setdefault(this.addressList[0].user_address_id)
                }
            }
        },
        // async getProvince() {
        //     const res = await getProvince()
        //     this.provinceList = res.detail
        // },
        showAddressPage(item) {
            console.log('item', item)
            this.current = item
            this.type = item ? 'edit' : 'add'
            this.showAddrPage = true
        },
        // 设置默认收货地址
        async setdefault(id) {
            const res = await setdefault({ user_address_id: id })
            this.getData()
            // this.$notify({ title: '提示', type: res.detail.status === 1 ? 'success' : 'error', message: res.msg, position: 'bottom-right' })
            this.$toast(res.msg)
        },
        // 删除收货地址
        async deladdress(id) {
            this.$messageBox.confirm('确定删除这个地址吗?').then(async action => {
                const res = await deladdress({ user_address_id: id })
                this.getData()
                // this.$notify({ title: '提示', type: res.detail.status === 1 ? 'success' : 'error', message: res.msg, position: 'bottom-right' })
                this.$toast(res.msg)
            }).catch(() => {})
        }
    }
}
</script>
<style lang="scss" scoped>
.my-title {
    margin-bottom: 20px;
    font-weight: normal;
    font-size: 18px;
}
.my-body {
    // min-height: 1000px;
    position: relative;
    // padding: 20px;
    // background-color: $white;
}
.address-btn-wrapper {
    margin-bottom: 20px;
}
.address-add-tips {
    padding: 10px 0;
    display: block;
    // text-align: center;
    color: $light;
}
.address-item-body {
    border-bottom: 1px solid $lightest;
}
.address-item {
    position: relative;
    margin-bottom: 10px;
    padding: 15px 20px;
    // border: 1px solid $lighter;
    background-color: $white;
}
.address-item-block {
    margin: 0 60px 15px 0;
    word-break: break-all;
    color: $light;
}
.address-name {
    font-weight: normal;
    color: $black3;
    // .btn-mini {
    //     background-color: $redlight;
    //     color: $red;
    // }
    // .btn-dark {
    //     @extend .btn-yellow;
    //     border-color: $light;
    //     background-color: $light;
    //     color: $white;
    // }
}
.address-name-txt {
    vertical-align: middle;
}
.address-toolbar {
    position: absolute;
    top: 40px;
    right: 20px;
    text-align: right;
    .iconfont {
        font-size: $fs24;
        color: $light;
    }
}
.address-link {
    @include inlineBlock();
    padding: 0 10px;
    color: $light;
}
.address-tag {
    margin-right: 5px;
    padding: 2px 3px;
    font-size: $fs12;
    vertical-align: middle;
    background-color: $redlight;
    color: $red;
}
.address-detail {
    vertical-align: -2px;
    font-size: $fs14;
    color: $black;
}
.address-item-footer {
    display: flex;
    justify-content: space-between;
    padding: 12px 0 0;
}
.form-check-icon {
    @include inlineBlock();
    width: 15px;
    height: 15px;
    border: 1px solid $lighter;
    .iconfont {
        display: none;
        font-size: $fs12;
    }
}
.form-check-label {
    vertical-align: middle;
    color: $black;
}
.form-check {
    .form-check-icon {
        margin-right: 5px;
    }
    &.active {
        .form-check-icon {
            border-color: $blue;
        }
        .iconfont {
            display: inline-block;
        }
    }
}
.address-none {
    margin: 200px 0;
    text-align: center;
    .iconfont {
        display: block;
        font-size: 50px;
        color: $redlighter;
    }
}
.address-none-tit {
    display: block;
    margin-bottom: 5px;
    font-weight: normal;
    font-size: $fs18;
}
.address-none-tips {
    font-size: $fs12;
    color: $light;
}
</style>
