<template>
    <div class="pro-item">
        <div class="thumb"><img :src="aftermarketProDetail.imgthumbnail" :alt="aftermarketProDetail.title"></div>
        <div class="infor">
            <h4 class="name"> {{ aftermarketProDetail.title }}</h4>
            <p>出荷时间： {{ parseInt(aftermarketProDetail.publishtype) === 1 ? '未定' : `${aftermarketProDetail.publishyear}年${aftermarketProDetail.publishtime}` }}</p>
            <p class="price"> 定价: {{ parseInt(aftermarketProDetail.moneytype) === 1 ?'未定价':`${aftermarketProDetail.rmb}元` }} </p>
        </div>
    </div>
</template>

<script>
export default {
    components: {},
    props: {
        aftermarketProDetail: {
            type: Object,
            default: null
        }
    },
    data() {
        return {
        }
    },
    computed: {},
    watch: {},
    methods: {}
}
</script>
<style lang="scss" scoped>
.pro-item{
    background-color: $white;
    padding: 16px;
    min-height: 112px;
    position: relative;
    .thumb{
        width:80px;height:80px;position: absolute;top: 16px;left: 16px; overflow: hidden;
        img{
            width: 100%;
        }
    }
    .infor{
        padding-left: 90px;color: #999;
        .name{
            font-size: 14px;color: #202020;padding-bottom: 10px;
            overflow: hidden;text-overflow: ellipsis;white-space: nowrap;
        }
        .price{
            position: absolute;left: 106px;bottom:16px;
        }
    }
}
</style>
