<template>
    <ul class="product-list">
        <li v-for="(item, index) in productList" :key="item.commodityid" class="product-item">
            <router-link class="product-link" :to="`/product/detail?commodityid=${item.commodityid}&commodity_details_id=${item.commodity_details_id}`">
                <img class="product-img" :src="item.img" :alt="item.title">
                <span class="product-name text-ellipsiss">{{ item.title }}</span>
                <!-- <span class="product-mask">
                    <i class="product-mask-icon">限购</i>
                    <i class="product-mask-txt">24小时55分钟46秒 截止，限购&nbsp;1&nbsp;个</i>
                </span> -->
                <span v-if="item.isseal===1" class="product-none">商品暂时无货</span>
            </router-link>
            <p class="product-price">
                <i>￥</i>{{ item.money1 === '0.00' ? item.money2 : item.money1 === item.money2 ? item.money1 : `${item.money1}-${item.money2}` }}
            </p>
            <p class="product-series">
                <span class="product-series-name">
                    出荷  {{ $utils.getPublishString(item.publishtype, item.publishyear, item.publishtime) }}
                </span>
                <!-- <span class="product-series-date"><i class="iconfont icon-arrow-round-r" /></span> -->
            </p>
        </li>
    </ul>
</template>
<script>
export default {
    name: 'ProductList',
    props: {
        productList: {
            type: Array,
            required: true,
            default: null
        }
    }
}
</script>
<style lang="scss" scoped>
    .product-item {
        display: inline-block;
        vertical-align: top;
        width: calc(50% - 10px);
        margin-right: 15px;
        margin-bottom: 20px;
        background-color: $white;
        border-radius: 6px;
        box-shadow: 5px 5px 5px $lighter;
        &:nth-child(2n) {
            margin-right: 0;
        }
    }
    .product-link {
        position: relative;
        display: block;
    }
    .product-img {
        display: block;
        width: 100%;
        height: 200px;
        object-fit: cover;
        border-radius: 6px;
    }
    .product-mask {
        position: absolute;
        top: 245px;
        left: 0;
        width: 100%;
        padding: 0 20px 0 70px;
        @include lineHeight(40px);
        color: $white;
        background-color: rgba(0, 0, 0, .2);
    }
    .product-mask-icon {
        position: absolute;
        top: -10px;
        left: 10px;
        @include widthHeight(45px, 50px);
        line-height: 50px;
        text-align: center;
        font-size: 14px;
        border-radius: 4px 4px 0 0;
        background-color: $reder;
    }
    .product-name {
        padding: 5px;
        display: block;
        height: 45px;
        overflow: hidden;
        font-size: 14px;
        color: $black;
    }
    .product-price {
        padding: 0 5px 5px;
        font-size: 18px;
        font-weight: bold;
        color: $red;
        > i {
            font-size: 14px;
        }
    }
    .product-price-item {
        padding: 6px 10px;
        display: block;
        color: $light;
    }
    .product-series {
        padding: 0 10px 10px;
        text-align: justify;
        color: $light;
    }
    .product-series-date {
        float: right;
        .icon {
            margin-top: -1px;
        }
    }
    .product-none {
        position: absolute;
        top: 170px;
        left: 0;
        width: 100%;
        height: 30px;
        line-height: 30px;
        text-align: center;
        color: $white;
        background: rgba($color: #000000, $alpha: .5);
    }
</style>
