
// @c an alias to /compontents
import pageHeader from '@c/pageHeader.vue'
import pageFooter from '@c/pageFooter.vue'
import productList from '@c/productList.vue'
import { getSeries } from '@api'
import { getCommodityList } from '@api/product'
import mixin from '@c/mixins'
import authMixin from '@c/mixins/auth'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
export default {
    name: 'Product',
    mixins: [mixin, authMixin],
    components: {
        pageHeader,
        pageFooter,
        productList,
        Swiper,
        SwiperSlide
    },
    watch: {
        '$route'(val) {
            this.title = val.query.title || ''
            this.getData()
        },
        type(val) {
            this.getData()
        },
    },
    data() {
        return {
            type: '0', // 0: 全部，1:预订，2:现货
            activeName: 'first',
            seriesList: [],
            productList: [],
            total: 0,
            currentPage: 1,
            seriesid: '',
            sex: 0,
            title: '',
            swiperOptions: {
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true
                },
                // autoplay: true, // 等同于以下设置
                loop: true,
                loopAdditionalSlides: 2,
                autoplay: {
                    delay: 10000,
                    stopOnLastSlide: false,
                    disableOnInteraction: false
                }
                // Some Swiper option/callback...
            },
            list: [
                { id: 1, img: require('@a/img/product.png'), link: '' }
            ]
        }
    },
    methods: {
        bannerUrl(item) {
            return item.informationid !== '0' ? `/news/detail?id=${item.informationid}` : `/product/detail?commodityid=${item.commodityid}`
        },
        // 获取筛选下拉
        async getSeries() {
            const res = await getSeries()
            this.seriesList = res.detail
        },
        // 获取资讯列表
        async getData() {
            const res = await getCommodityList({
                seriesid: this.seriesid,
                sex: this.sex,
                type: this.type,
                ordertype: this.ordertype,
                page: this.currentPage,
                title: this.title
            })
            if (res && res.list) {
                // this.productList = res.list.list.slice(0, 4)
                this.productList = res.list.list
                this.total = res.list.total
            }
        }
    },
    created() {
        this.getSeries()
        this.getAccount()
    },
    beforeRouteEnter(to, from, next) {
        console.log('to, from', to, from)
        next((vm) => {
            vm.title = to.query && to.query.title ? to.query.title : ''
            vm.getData()
        })
    }
}
