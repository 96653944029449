import mixin from '@c/mixins'
import { sendMsg, getMobileType, loginByPassword, loginByCode } from '@api'
export default {
    name: 'Login',
    mixins: [mixin],
    data() {
        return {
            popupVisible: false,
            loginType: 2,
            ruleForm: {
                mobiletype: 1, // ''=>请选择区号 1 中国大陆 2香港 3澳门 4台湾
                username: '',
                password: '',
                code: ''
            },
            remeber: true,
            showPassword: false,
            timer: null,
            countDownTime: 0,
            countDownDisabled: false,
            mobiletypes: [],
            mobiletypeItem: null
        }
    },
    created() {
        this.getMobileTypes()
        const cacheUserName = localStorage.getItem('USER_ACCOUT')
        if (cacheUserName) {
            this.ruleForm.username = cacheUserName
        }
        // 获取上次倒计时时间
        const currentTime = new Date().getTime()
        const lastTime = +localStorage.getItem('COUNTDOWN_LOGIN')
        this.countDownTime = Math.floor(60 - (currentTime - lastTime) / 1000)
        console.log('countDownTime created', this.countDownTime)
        this.countDown()
    },
    // mounted() {
    // console.log('passWordInput icon dom', document.querySelector('.el-input__icon.el-icon-view.el-input__clear'))
    // console.log('passWordInput dom', document.querySelector('#passWordInput'))
    // const eyeIcon = document.querySelector('#passWordInput .el-input__icon.el-icon-view.el-input__clear')
    // eyeIcon.addEventListener('click', function() {
    //     this.showPassword = !this.showPassword
    // })
    // },
    // 实例销毁前清除定时器
    beforeDestroy() {
        // 记录当前时间更新缓存
        // localStorage.setItem('COUNTDOWN_LOGIN', new Date().getTime())
        // 更新缓存
        if (this.timer) window.clearInterval(this.timer)
    },
    // 退出路由前清除定时器
    beforeRouteLeave(to, from, next) {
        // 导航离开该组件的对应路由时调用
        // 可以访问组件实例 `this`
        if (this.timer) window.clearInterval(this.timer)
        // 记录当前时间更新缓存
        // localStorage.setItem('COUNTDOWN_LOGIN', new Date().getTime())
        next()
    },
    methods: {
        switchLoginType(type) {
            this.loginType = type
            this.$nextTick(_ => {
                console.log('this.$refs.ruleForm', this.$refs.ruleForm)
                if (this.$refs.ruleForm) {
                    this.$refs.ruleForm.clearValidate()
                }
            })
        },
        async getMobileTypes() {
            const res = await getMobileType()
            if (res.code === 1001) {
                // console.log(res.detail)
                res.detail.forEach(item => {
                    item['selected'] = false
                })
                this.mobiletypes = res.detail
                const cacheMobiletype = localStorage.getItem('USER_MOBILETYPE')
                // console.log(cacheMobiletype)
                if (cacheMobiletype) {
                    this.mobiletypeSelect(parseInt(cacheMobiletype))
                } else {
                    this.mobiletypeSelect(1) // 默认选中中国区
                }
            }
        },
        // mobiletype 选择
        mobiletypeSelect(mobiletype) {
            // const idx = this.mobiletypes.findIndex(item => item.mobiletype === mobiletype)
            // console.log(idx)
            // this.mobiletypes[idx].selected = true
            this.mobiletypes.forEach(item => {
                item.selected = false
                if (item.mobiletype === mobiletype) {
                    item.selected = !item.selected
                    this.ruleForm.mobiletype = item.mobiletype
                    this.mobiletypeItem = item
                    // console.log(this.ruleForm)
                    this.mobilePopupHide()
                }
            })
        },
        mobilePopupShow() {
            this.popupVisible = true
        },
        mobilePopupHide() {
            this.popupVisible = false
        },
        // 发送短信
        async sendMsg() {
            if (!this.ruleForm.username) {
                this.$toast('请输入手机号')
                return
            }
            const res = await sendMsg({
                mobiletype: this.ruleForm.mobiletype,
                mobile: this.ruleForm.username,
                type: 1
            })
            const msg = [
                '登录成功',
                '用户名或密码不符合规范',
                '账号或密码错误',
                '该账号已被封禁不可登录'
            ]
            console.log('res', res)
            const status = res.detail.status
            // this.$notify({ title: '提示', type: status === 1 ? 'success' : 'error', message: msg[status] || '未知错误', position: 'bottom-right' })
            if (status !== 1) {
                // 重设时间
                this.countDownTime = 0
                this.countDownDisabled = false
                // 清除定时器
                if (this.timer) window.clearInterval(this.timer)
            } else {
                // 重设时间
                this.countDownTime = this.countDownTimeMax
                // 记录当前时间更新缓存
                localStorage.setItem('COUNTDOWN_LOGIN', new Date().getTime())
                // 开始倒计时
                this.countDown()
            }
        },
        async onSubmit() {
            // this.$refs['ruleForm'].validate(async valid => {
            // if (valid) {
            console.log('ruleForm', this.ruleForm);
            if (!this.ruleForm.username) {
                this.$toast('请输入手机号')
                return
            }
            if (this.loginType === 1 && !this.ruleForm.password) {
                this.$toast('请输入密码')
                return
            }
            if (this.loginType === 2 && !this.ruleForm.code) {
                this.$toast('请输入验证码')
                return
            }
            const res = this.loginType === 1 ? await loginByPassword(this.ruleForm) : await loginByCode(this.ruleForm)
            console.log('res', res)
            const msg = [
                '登录成功',
                '用户名或密码不符合规范',
                '账号或密码错误',
                '该账号已被封禁不可登录'
            ]
            const status = +res.detail.status
            console.log('login status', status)
            // this.$notify({ title: '提示', type: status === 1 ? 'success' : 'error', message: res.msg || msg[status - 1], position: 'bottom-right' })
            this.$toast(res.msg || msg[status - 1])
            // 登录成功跳转首页或者重定向页
            if (status === 1) {
                // 记住账号（永久）
                if (this.remeber) {
                    localStorage.setItem('USER_ACCOUT', this.ruleForm.username)
                    localStorage.setItem('USER_MOBILETYPE', this.ruleForm.mobiletype)
                } else {
                    localStorage.removeItem('USER_ACCOUT')
                    localStorage.removeItem('USER_MOBILETYPE')
                }
                // 通知userInfo组件获取登录信息
                // this.$bus.$emit('getUserInfo')
                const redirectUrl = this.$route.query.redirectUrl
                if (redirectUrl) {
                    this.$router.push(redirectUrl)
                } else {
                    this.$router.push('/')
                }
            } else {
                this.ruleForm.code = ''
                this.$refs['ruleForm'] && this.$refs['ruleForm'].clearValidate()
            }
            // }
            // })
        }
    }
}
