import pageHeader from '@c/pageHeader.vue'
// import pageFooter from '@c/pageFooter.vue'
// import breadcrumbs from '@c/breadcrumbs.vue'
import sideImgList from '@c/sideImgList.vue'
import sideCommodity from '@c/sideCommodity.vue'
import sideInformation from '@c/sideInformation.vue'
import mixin from '@c/mixins'
import authMixin from '@c/mixins/auth'
import { getInformationDetail, iscollection, collection } from '@api/news'
export default {
    name: 'NewsDetail',
    mixins: [ mixin, authMixin ],
    components: {
        pageHeader,
        // pageFooter,
        sideImgList,
        sideCommodity,
        sideInformation
    },
    data() {
        return {
            detail: {},
            informationid: '',
            isCollection: false,
            // userInfo: {}
        }
    },
    watch: {
        '$route'() {
            this.getQueryData()
        }
    },
    methods: {
        getQueryData() {
            this.informationid = this.$route.query.id
            if (this.informationid) {
                this.getData(this.informationid)
                // 获取登录信息
                // this.$bus.$on('userInfo', userInfo => {
                    // this.userInfo = Object.assign({}, userInfo)
                    console.log('this.userInfo', this.userInfo)
                    // 登录后才有关注功能
                    if (Object.keys(this.userInfo).length > 0) {
                        this.iscollection(this.informationid)
                    }
                // })
            }
        },
        /**
         * 是否关注了 该资讯或者商品
         * 必传: paramid（资讯的时候 是informationid,商品的时候 是commodityid），type（1 资讯 2 商品）
         */
        async iscollection(paramid) {
            const res = await iscollection({
                paramid,
                type: 1
            })
            const detail = res ? res.detail : null
            if (detail) {
                this.isCollection = detail.status === '1'
            }
        },
        /**
         * 关注（取消关注）该资讯或者商品
         * 必传: paramid（资讯的时候 是informationid,商品的时候 是commodityid），type（1 资讯 2 商品）
         */
        async collection(paramid, status = 1) {
            console.log('status', status)
            console.log('this.isCollection', this.isCollection)
            // 判断用户是否已登录
            if (Object.keys(this.userInfo).length === 0) {
                this.$router.push('/login')
                return
            }
            const res = await collection({
                paramid,
                type: 1,
                status
            })
            const detail = res ? res.detail : null
            // if (detail && detail.status) {
            //     this.isCollection = detail.status === 1
            //     this.iscollection(paramid)
            // }
            this.iscollection(paramid)
            // this.$notify({ title: '提示', type: detail.status === 1 ? 'success' : 'error', message: res.msg, position: 'bottom-right' })
            this.$toast(res.msg)
        },
        // 获取资讯详情
        async getData(informationid) {
            const res = await getInformationDetail({
                informationid
            })
            if (res && res.detail) {
                this.detail = Object.assign({}, this.detail, res.detail)
            }
        }
    },
    beforeRouteEnter(to, from, next) {
        console.log('from, to', from, to)
        next(vm => {
            vm.getAccount()
            // console.log(vm.$route.query)
            vm.informationid = vm.$route.query.id
            if (vm.informationid) {
                vm.getData(vm.informationid)
                // 获取登录信息
                // const cacheUserInfo = sessionStorage.getItem('USER_INFO')
                if (vm.userInfo) {
                    // 登录后才有关注功能
                    vm.iscollection(vm.informationid)
                }
                // vm.$bus.$on('userInfo', userInfo => {
                //     vm.userInfo = Object.assign({}, userInfo)
                //     console.log('vm.userInfo', vm.userInfo)
                //     // 登录后才有关注功能
                //     if (Object.keys(vm.userInfo).length > 0) {
                //         vm.iscollection(vm.informationid)
                //     }
                // })
            }
        })
    }
}
