<template>
    <div class="main aftermarket" :class="{'pb70':isBtnShow}">
        <!-- <page-header :userInfo="userInfo" title="售后详情" :hasMore="false" /> -->
        <section class="top-info">
            <h3 class="til">售后信息</h3>
            <p class="ti-item">售后单号：{{ aftermarketDetail.aftermarketorderid }}</p>
            <p class="ti-item">售后状态：{{ getStatusTxt }}</p>
            <p class="ti-item">提交时间：{{ aftermarketDetail.addtime }}</p>
        </section>
        <!-- <section class="pro-item">
            <div class="thumb"><img :src="aftermarketDetail.imgthumbnail" :alt="aftermarketDetail.title"></div>
            <div class="infor">
                <h4 class="name"> {{ aftermarketDetail.title }}</h4>
                <p>出荷时间： {{ parseInt(aftermarketDetail.publishtype) === 1 ? '未定' : `${aftermarketDetail.publishyear}年${aftermarketDetail.publishtime}` }}</p>
                <p class="price"> 定价: {{ parseInt(aftermarketDetail.moneytype) === 1 ?'未定价':`${aftermarketDetail.rmb}元` }} </p>
            </div>
        </section> -->
        <aftermarket-pro-item :aftermarket-pro-detail="aftermarketDetail" />
        <div v-if="aftermarketDetail.buttontype === 1" class="aftermarket-item afc-item">
            <div class="afc-head">
                <h3 class="til">售后发回地址</h3>
            </div>
            <div class="afc-body">
                <div class="kefu-info">
                    <img :src="aftermarketDetail.kefuheadimgurl" :alt="aftermarketDetail.kefuname" class="avatar-img">
                    <div class="infor">
                        <span class="name"> {{ aftermarketDetail.kefuname }} </span>
                        <span class="date txt-grey"> {{ aftermarketDetail.kefuaddtime }} </span>
                    </div>
                </div>
                <div class="kefu-address"> 地址：{{ aftermarketDetail.kefuaddress }} </div>
            </div>
        </div>
        <history v-show="!addDesDialogVisible && !addSetDialogVisible && !addResDialogVisible" :init-history-list="historyList" />
        <div v-if="isBtnShow" v-show="!addDesDialogVisible && !addSetDialogVisible && !addResDialogVisible" class="afc-btn-wrapper">
            <a v-if="isBtnShow" href="javascript:void(0);" class="btn-primary btn-round" @click="handleCancel">取消售后申请</a>
            <a v-if="isAddDescBtnShow" href="javascript:void(0);" class="btn-primary btn-round" @click="addDesDialogVisible=true">新增描述</a>
            <a v-if="aftermarketDetail.status === '6' && aftermarketDetail.buttontype === 1" href="javascript:void(0);" class="btn-primary btn-round" @click="addSetDialogVisible=true">添加收发信息</a>
            <a v-if="aftermarketDetail.status === '6' && aftermarketDetail.buttontype === 2" href="javascript:void(0);" class="btn-primary btn-round" @click="addResDialogVisible=true">填写收货信息</a>
        </div>
        <add-des v-show="addDesDialogVisible" :aftermarketid="aftermarketDetail.id" @getData="getAftermarketDetail" @close="addDesDialogVisible=false" />
        <add-set v-show="addSetDialogVisible" :aftermarketid="aftermarketDetail.id" @getData="getAftermarketDetail" @close="addSetDialogVisible=false" />
        <add-res v-show="addResDialogVisible" :aftermarketid="aftermarketDetail.id" @getData="getAftermarketDetail" @close="addResDialogVisible=false" />
    </div>
</template>
<script>
import history from './history.vue'
import addDes from './addDes.vue'
import addSet from './addSet.vue'
import addRes from './addRes.vue'
import aftermarketProItem from '../proItem.vue'
import mixin from '@c/mixins'
import authMixin from '@c/mixins/auth'
import { getAftermarketDetail, addAftermarketDetail } from '@api/aftermarket'
import { MessageBox } from 'mint-ui'
// import '../../mock'
export default {
    name: 'AftermarketDetail',
    components: {
        history,
        addDes,
        addSet,
        addRes,
        aftermarketProItem
    },
    mixins: [mixin, authMixin],
    data() {
        return {
            validOrderHeadList: ['商品', '总金额（元）'],
            addDesDialogVisible: false,
            addSetDialogVisible: false,
            addResDialogVisible: false,
            aftermarketorderid: '',
            aftermarketDetail: {},
            historyList: [],
            aftermarketStep: 0,
            aftermarketDetailStatus: [
                { id: 1, statusTxt: ' 待审核 ' },
                { id: 2, statusTxt: ' 审核已通过 ' },
                { id: 3, statusTxt: ' 审核未通过 ' },
                { id: 4, statusTxt: ' 售后完成 ' },
                { id: 5, statusTxt: ' 售后已取消 ' },
                { id: 6, statusTxt: ' 资料待填写 ' },
                { id: 7, statusTxt: ' 待售后完成 ' }
            ]
        }
    },
    computed: {
        // eslint-disable-next-line vue/return-in-computed-property
        getStatusTxt() {
            if (this.aftermarketDetail.status) {
                return this.aftermarketDetailStatus.filter((item) => item.id === parseInt(this.aftermarketDetail.status))[0].statusTxt
            }
        },
        isAddDescBtnShow() {
            const __status = this.aftermarketDetail.status
            return __status === '1' || __status === '2' || __status === '3'
        },
        isBtnShow() {
            const __status = this.aftermarketDetail.status
            return __status === '1' || __status === '2' || __status === '3' || __status === '6'
        }
    },
    created() {
        this.getAccount()
    },
    methods: {
        async getAftermarketDetail() {
            const res = await getAftermarketDetail({ aftermarketorderid: this.aftermarketorderid })
            console.log('getAftermarketDetail res', res)
            const resDetail = res.detail
            if (resDetail && resDetail.aftermarket) {
                this.aftermarketDetail = Object.assign({}, this.aftermarketDetail, resDetail.aftermarket)
                this.historyList = resDetail.detail
                // console.log('historyList', this.historyList, this.historyList.find(item => ['7', '8'].includes(item.type)))
                if (this.historyList[0]) {
                    this.historyList.forEach(item => {
                        if (item.logistics) {
                            this.$set(item.logistics, 'showPopover', false)
                        }
                    })
                }
                if (this.aftermarketDetail.time2) {
                    this.aftermarketStep = 1
                }
                if (this.aftermarketDetail.time3) {
                    this.aftermarketStep = 2
                }
            }
            console.log('aftermarketDetail', this.aftermarketDetail)
        },
        handleCancel() {
            MessageBox.confirm('确定要取消售后申请吗?').then(async action => {
                const res = await addAftermarketDetail({ aftermarketid: this.aftermarketDetail.id, type: 4 })
                console.log('cancel res', res)
                const resDetail = res.detail
                if (resDetail && resDetail.status === 1) {
                    // this.$notify({ title: '提示', type: resDetail.status === 1 ? 'success' : 'error', message: resDetail.status === 1 ? '操作成功' : '操作失败', position: 'bottom-right' })
                    this.$toast(resDetail.status === 1 ? '操作成功' : '操作失败')
                }
                if (resDetail.status === 1) {
                    this.$router.push('/my/aftermarket/list')
                }
            })
        }
    },
    beforeRouteEnter(to, from, next) {
        console.log('from, to', from, to)
        next(vm => {
            console.log('vm.$route.query', vm.$route.query)
            vm.aftermarketorderid = vm.$route.query['aftermarketorderid']
            if (vm.aftermarketorderid) {
                vm.$set(vm.aftermarketDetail, 'aftermarketorderid', vm.aftermarketorderid)
                vm.getAftermarketDetail()
            }
        })
    }
}
</script>
<style lang="scss" scoped>
.aftermarket{
    &.pb70{
        padding-bottom: 70px;
    }
}
.top-info {
    margin-bottom: 10px;
    padding: 14px 16px;
    background-color: $white;
}
.til{
    font-size: 15px;color: #333;padding-bottom: 10px;
}
.ti-item {
    color: #999;font-size: 12px;
    padding: 4px 0;
    &:last-child {
        border-bottom: 0;
    }
}
.aftermarket-item {
    position: relative;
    // width: 1200px;
    padding: 14px 16px;
    margin: 10px auto;
    border: none;
    background-color: $white;
    &.mt20 {
        margin-top: 20px;
    }
}
.afc-tit {
    margin-bottom: 10px;
    font-weight: normal;
}
.afc-btn-wrapper {
    position: fixed;left: 0;right: 0;bottom: 0;
    padding: 15px 16px;
    background-color: #fff;
    display: flex;
    justify-content: center;
    .btn-primary {
        width: 50%;box-sizing: border-box;
        font-size: 10px;
        display: block;
        padding: 0;
        height: 40px;
        line-height: 38px;
        &:first-child{
            background: none;
            color: red;
        }
    }
}

.kefu-info{
    overflow: hidden;margin-bottom:8px;
    .avatar-img{
        width: 36px;height: 36px;float: left;
        object-fit: cover;
    }
    .infor{
        padding-left: 10px;
        float: left;
        .name{
            display: block;padding-bottom: 2px;padding-top:2px;
        }
    }
}

</style>
